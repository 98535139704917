/* eslint-disable react/react-in-jsx-scope */
import {
  Fragment,
  h
} from 'preact';
import { useState } from 'preact/hooks';
import {
  adminUserData,
  dlocUserData,
  locUserData
} from '../../data';
import { BackupCredentials } from '../backup-codes/recover/BackupCredentials';
import { RecoverClient } from '../backup-codes/recover/types';

import './styles.scss';

interface AccountRecoveryClient extends RecoverClient {
  userData: dlocUserData | locUserData | adminUserData;
  token: string;
  validateSecurityQuestionAnswers: (answers: string[], token: string) => Promise<boolean>;
  submitEvent: (action: string, data: Record<string, unknown>) => Promise<void>;
}

export const AnswerSecurityQuestions = ({
  client,
  type
}: {
  client: AccountRecoveryClient;
  type: string;
  path?: string;
}): JSX.Element => {
  if (!client.userData?.securityQuestions) {
    return (
      <main id="answerQuestionsPage">
        <section className="formWrapper">
          <h1>Error loading security questions</h1>
          <p>Try loading this page again by clicking the link in the email you received.</p>
        </section>
      </main>
    );
  }

  const [answer0, setAnswer0] = useState<string>('');
  const [answer1, setAnswer1] = useState<string>('');
  const [answer2, setAnswer2] = useState<string>('');
  const [answersValidated, setAnswersValidated] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const onSubmit = async () => {
    const loader = document.getElementById('loaderWrapper');
    loader.style.display = 'flex';

    if (!(answer0 && answer1 && answer2)) {
      setError('Please answer all of the questions');
      return;
    }

    try {
      const success = await client.validateSecurityQuestionAnswers([answer0, answer1, answer2], client.token);
      setAnswersValidated(success);
      if (!success) {
        setError('There was an error recovering your account. Please try again.');
      }
    } catch (e) {
      try {
        await client.submitEvent(`Answer security questions (${type})`, { error: (e as Error).message });
      } catch {
        // Swallow any errors here
      }
    } finally {
      loader.style.display = 'none';
    }
    setError('There was an error recovering your account. Please try again.');
  };
  return (
    <Fragment>
      {!answersValidated && (
        <main id="answerQuestionsPage">
          <section className="formWrapper">
            <h1>Security Questions</h1>
            <p>Remember that answers are case-sensitive</p>

            <div id="questionForm">
              <p>{client.userData.securityQuestions[0]}</p>
              <input
                placeholder="Your answer"
                onChange={(e) => {
                  e.preventDefault();
                  setAnswer0(e.currentTarget.value);
                }}
              />

              <p>{client.userData.securityQuestions[1]}</p>
              <input
                placeholder="Your answer"
                onChange={(e) => {
                  e.preventDefault();
                  setAnswer1(e.currentTarget.value);
                }}
              />

              <p>{client.userData.securityQuestions[2]}</p>
              <input
                placeholder="Your answer"
                onChange={(e) => {
                  e.preventDefault();
                  setAnswer2(e.currentTarget.value);
                }}
              />

              {error && (
                <p style={{ color: 'red' }}>{error}</p>
              )}

              <div>
                <button className="btn primary" onClick={onSubmit}>
                Submit
                </button>
              </div>
            </div>
          </section>
          <div id="loaderWrapper">
            <div className="loaderContainer">
              <div className='loader' />
            </div>
          </div>
        </main>
      )}
      {answersValidated && (
        <BackupCredentials client={client} questionsAnswered={true} />
      )}
    </Fragment>
  );
};
