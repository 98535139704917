/* eslint-disable react/react-in-jsx-scope */
import { h } from 'preact';
import {
  useContext,
  useEffect,
  useState
} from 'preact/hooks';
import { Modal } from '../../../../../../lib/components/modal';
import { SubmitCancelReturn } from '../../../../../../lib/components/SubmitCancelReturn';
import { legalNotes } from '../../../../../../lib/data';
import { LegalClientContext } from '../../components/LegalClientContext';
import { Toast } from '../../../../../../lib/components/toast';
import { ActionTimer } from '../../../../../../lib/components/ActionTimer';

export const ViewEditNotes = ({
  onClose,
  caseId,
  survivorName,
  mode,
  notes = null
}: {
  onClose: () => void;
  caseId: string;
  survivorName: string;
  mode: string;
  notes?: legalNotes;
}): JSX.Element => {
  const [editMode, setEditMode] = useState<string>(mode);
  const [currentNotes, setCurrentNotes] = useState<legalNotes>(notes);
  const [notesText, setNotesText] = useState<string>(notes?.notes);
  const [changesMade, setChangesMade] = useState<boolean>(false);
  const [saveSuccessful, setSaveSuccessful] = useState<boolean>(false);
  const [showSubmitCancelReturn, setShowSubmitCancelReturn] = useState<boolean>(false);
  const legalClient = useContext(LegalClientContext);

  useEffect(() => {
    if (notes) {
      setCurrentNotes({ ...legalClient.caseNotes[caseId].find((n) => (n.id === notes.id)) });
    } else if (currentNotes) {
      setCurrentNotes({ ...legalClient.caseNotes[caseId].find((n) => (n.id === currentNotes.id)) });
    }
  });

  const handleSave = async () => {
    if (currentNotes) {
      await legalClient.updateNotes(caseId, currentNotes.id, notesText);
    } else {
      const notesId = await legalClient.submitNotes(caseId, notesText);
      setCurrentNotes({ ...legalClient.caseNotes[caseId].find((n) => (n.id === notesId)) });
    }
    setSaveSuccessful(true);
    setChangesMade(false);
  };

  return (
    <Modal minWidth="95%" canExit={false} closeFunc={async () => {
      onClose();
      return Promise.resolve();
    }}>
      <ActionTimer timeoutInMinutes={25} onTimeout={async () => {
        if (changesMade) {
          await handleSave();
        }
      }}>
        <h1>{editMode === 'view' ? 'View' : 'Edit'} Notes</h1>

        <button className="iconButton" onClick={() => { setEditMode(editMode === 'view' ? 'edit' : 'view'); }}>
          <img
            src={require('../../../assets/icons/edit.svg') as string}
            alt="toggle edit mode"
          /> {editMode === 'view' ? 'Edit' : 'View'}
        </button>

        <p><b>Case ID:</b> {caseId}</p>
        <p><b>Survivor Name:</b> {survivorName}</p>
        <p><b>Created:</b> {currentNotes?.createdDate?.toLocaleString()}</p>
        <p><b>Updated:</b> {currentNotes?.modifiedDate?.toLocaleString()}</p>
        <h2>Notes</h2>
        <p><textarea
          value={notesText}
          disabled={editMode === 'view' || showSubmitCancelReturn}
          style={{ width: '100%', height: '400px', fontSize: '12pt', color: '#225a75' }}
          onChange={(e) => {
            setNotesText(e.currentTarget.value);
            if (!changesMade) {
              setChangesMade(true);
            }
          }}
        /></p>

        {showSubmitCancelReturn && (
          <SubmitCancelReturn
            submitText="Save and Close"
            cancelText="Discard Changes"
            returnText="Return to Notes"
            headerText="You have unsaved changes. If you close without saving, you will lose the changes."
            onReturn={() => {
              setShowSubmitCancelReturn(false);
            }}
            onCancel={onClose}
            onSubmit={async () => {
              await handleSave();
              onClose();
            }}
          />
        )}

        {!showSubmitCancelReturn && (
          <div>
            <button
              className="secondaryButton rightAligned"
              onClick={() => {
                if (changesMade) {
                  setShowSubmitCancelReturn(true);
                } else {
                  onClose();
                }
              }}
            >Close</button>
            {editMode !== 'view' && (
              <button
                className="primaryButton saveButton rightAligned"
                disabled={!changesMade}
                onClick={handleSave}
              >Save</button>
            )}
            {saveSuccessful && (
              <Toast
                error={false}
                message="Notes saved successfully"
                closeFunc={async () => {
                  setSaveSuccessful(false);
                  return Promise.resolve();
                }}
              />
            )}
          </div>
        )}
      </ActionTimer>
    </Modal>
  );
};
