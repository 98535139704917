/* eslint-disable react/react-in-jsx-scope */
import { h, Fragment } from 'preact';
import { useState } from 'preact/hooks';
import { BackupCodesModal } from './backup-codes/modal';

interface Client {
  emailBackupCodes: (email: string, codes: string[]) => Promise<void>;
  createBackups(count: number): Promise<string[]>;
}

export const BackupCodesButton = ({ client }: { client: Client }): JSX.Element => {
  const [showBackupModal, setBackupModal] = useState(false);
  const [backupCodes, setBackupCodes] = useState<string[]>([]);

  const showBackupCodes = () => {
    setBackupModal(true);
    setBackupCodes([]);

    // Need to schedule this into a future render loop..
    setTimeout(() => {
      void client.createBackups(5).then((codes) => {
        setBackupCodes(codes);
      });
    }, 500);
  };

  return (
    <Fragment>
      <button type="button" className="secondaryButton" onClick={showBackupCodes}>
        Generate New Backup Codes
      </button>
      {showBackupModal && (
        <BackupCodesModal
          client={client}
          codes={backupCodes}
          onClose={() => setBackupModal(false)}
        />
      )}
    </Fragment>
  );
};
