/* eslint-disable react/react-in-jsx-scope */
import { h, Fragment } from 'preact';
import { route } from 'preact-router';

import './styles.scss';
import { Toast } from '../toast';
import { useAuthState } from '../../init/store';
import { useState } from 'preact/hooks';
import { PasswordInput } from '../PasswordInput';

interface LoginClient {
  token: string;
  login(username: string, password: string): Promise<void>;
}

export const LoginPage = ({
  target,
  client,
  portalName,
  token,
  link,
}: {
  target: string;
  client: LoginClient;
  portalName: string;
  path?: string;
  token?: string;
  link?: string;
}): JSX.Element => {
  const { login, wasLoggedIn, clearWasLoggedIn } = useAuthState();
  const [showError, setShowError] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const doLogin = () => {
    const loader = document.getElementById('loaderWrapper');
    loader.style.display = 'flex';

    setTimeout(() => {
      if (token) {
        client.token = token;
      }
      client
        .login(username, password)
        .then(() => {
          login();
          if (link && link !== '/') {
            route(link);
          } else {
            route(target);
          }
        })
        .catch(() => {
          setShowError(true);
        })
        .finally(() => {
          loader.style.display = 'none';
        });
    }, 100);
  };

  return (
    <Fragment>
      <main id="loginPage">
        <section className="formWrapper">
          <div id="titleWrapper">
            <h1>Log in to Callisto Vault {portalName}</h1>
          </div>
          <div id="credForm">
            <input
              className="cred"
              id="username"
              type="text"
              name="username"
              placeholder="Username"
              autoComplete="false"
              value={username}
              onChange={(evt) => setUsername(evt.currentTarget.value)}
            />

            <PasswordInput
              placeholder="Password"
              value={password}
              onChange={(p) => setPassword(p)}
            />

            <div id="next5" className="show">
              <button className="btn primary" onClick={doLogin}>
                Log in
              </button>
            </div>
          </div>
          <p className="helperText">
            <a href="/reset-password">Forgot password?</a>
          </p>
        </section>

        <div id="loaderWrapper">
          <div className="loaderContainer">
            <div className='loader'/>
          </div>
        </div>
      </main>

      {showError && (
        <Toast
          error={true}
          message="The username or password you entered is incorrect. Please try again."
          closeFunc={async () => Promise.resolve(setShowError(false))}
        />
      )}

      {wasLoggedIn && (
        <Toast
          error={false}
          message="You have been logged out."
          closeFunc={async () => Promise.resolve(clearWasLoggedIn())}
        />
      )}
    </Fragment>
  );
};
