/* eslint-disable react/react-in-jsx-scope,@typescript-eslint/require-await */
import {
  Fragment,
  h
} from 'preact';
import {
  animateOut,
  Modal
} from '../../../../../../lib/components/modal';
import { route } from 'preact-router';
import {
  useContext,
  useState
} from 'preact/hooks';
import { LegalClientContext } from '../LegalClientContext';
import { locEntry } from '../../../../../../lib/data';
import { useCases } from '../../legalData';

type SimpleCB = () => void;

const RenderComplete = ({ onClose }: { onClose: SimpleCB }) => (
  <Fragment>
    <div>
      <p>
        <b>Your request for re-assignment has been sent to Callisto.</b>
      </p>
      <p>Thank you for your time.</p>
    </div>
    <div id="codesButtons" className="hideOnPrint topPadding">
      <button className="primaryButton" onClick={onClose}>
          Return to dashboard
      </button>
    </div>
  </Fragment>
);

const RenderForm = ({
  errText,
  onClose,
  onOk,
}: {
  errText: string;
  onClose: SimpleCB;
  onOk: SimpleCB;
}) => (
  <div id="modal-form">
    <div>
      <p>
        <b>
            Are you sure you’d like to have this case reassigned to another
            Legal Options Counselor?
        </b>
      </p>
      <p>
          Taking this action will alert Callisto to reassign the case to another
          Legal Options Counselor. No further action will be needed from you.
      </p>
    </div>
    <p className="errorText">{errText}</p>
    <div id="codesButtons" className="hideOnPrint topPadding">
      <button id="completeButton" className="secondaryButton" onClick={onClose}>
          No, cancel
      </button>
      <button className="primaryButton" onClick={onOk}>
          Yes, reassign
      </button>
    </div>
  </div>
);

export const ReassignModal = ({
  closeFunc,
  entry,
  onlyCaseForSurvivor
}: {
  closeFunc: SimpleCB;
  entry: locEntry;
  onlyCaseForSurvivor: boolean;
}): JSX.Element => {
  const [errText, setErrText] = useState('');
  const [complete, setComplete] = useState(false);
  const { refreshCases } = useCases();
  const client = useContext(LegalClientContext);

  const closeOut = () => {
    void animateOut(async () => closeFunc());
  };

  const closeToDashboard = () => {
    closeOut();
    route('/dashboard');
  };

  const confirmReassign = () => {
    setErrText('');

    const modalLoader = document.getElementById('modalLoader');
    modalLoader.style.display = 'flex';

    client
      .reassignCase(entry.id, onlyCaseForSurvivor ? entry.entry.userID : null)
      .then(() => {
        refreshCases();
        setComplete(true);
        closeToDashboard();
      })
      .catch(() => {
        setErrText(
          'There was a problem reassigning the case. Please try again.'
        );
      })
      .finally(() => {
        modalLoader.style.display = 'none';
      });
  };

  return (
    <Modal closeFunc={async () => closeFunc()} canExit={true}>
      {complete ? (
        <RenderComplete onClose={closeToDashboard} />
      ) : (
        <RenderForm
          errText={errText}
          onClose={closeOut}
          onOk={confirmReassign}
        />
      )}
    </Modal>
  );
};
