import {
  crypto_hash_sha256,
  randombytes_buf,
  to_hex
} from 'libsodium-wrappers-sumo';

import {
  Box,
  KeyGenerationHelper,
  Matching,
  OPAQUE,
  OPRF,
  Secretbox
} from '../../../lib/crypto';
import { RPCClient } from '../../../lib/rpc';
import * as inner from '../../../lib/data';
import {
  legalNotes,
  locUserData,
  SurveyResponse
} from '../../../lib/data';
import { endpoints as v1 } from '../endpoints';
import {
  tEncryptedBox,
  tKey,
  tLegalNotes,
  tLocUserData,
  tVersionedKeyBox
} from '../../server/util/iots';
import sha1 from 'js-sha1';
import { computeShares } from '../../../lib/util/shares';
import { RecoveryClient } from '../../recoveryserver/client';

// This is cached here since it never ended up changing between environments.
const commonBadgeSalt = '0ddMh4J8gWtnC4Gt';

export class LegalClient extends RPCClient {
  public contact = {
    email: '',
    name: '',
    practice: '',
    state: '',
  };

  public token: string = null;
  public envVars: Record<string, string> = {};

  public versionedKeys: Record<number, {
    publicKey: Uint8Array;
    privateKey: Uint8Array;
  }> = {};

  public isLocStar = false;
  public caseNotes: Record<string, legalNotes[]> = {};
  public isAbleToRewriteShares = false;
  public userData: locUserData = {};
  private recoveryClient: RecoveryClient;

  constructor(baseURL?: string) {
    super();
    if (!baseURL) {
      baseURL = process.env.APP_SERVER_URL;
    }
    // TODO: move this into the base class!
    this.baseURL = baseURL !== undefined ? baseURL : '';
  }

  public async getEnvironmentVariables(): Promise<Record<string, string>> {
    this.envVars = await this.call(v1.GetFrontEndEnvironmentVariables)({});
    return this.envVars;
  }

  // Login =====================================================================
  public async login(
    username: string,
    password: string,
    badgeSalt = commonBadgeSalt
  ): Promise<void> {
    const lowercaseUsername = username.toLowerCase();
    // Try using just the username as the index first.
    // This modification was made to make our instance of OPAQUE more secure
    // against dictionary attacks than the original was.
    const usernameHash = OPAQUE.makeUsername(lowercaseUsername);
    const passwordHash = OPAQUE.makePassword(password, badgeSalt);
    const alpha = OPAQUE.mask(passwordHash);

    try {
      await this.doLogin(lowercaseUsername, usernameHash, alpha);
    } catch {
      try {
        // Try to use a lowercase username first, since that will be the case
        // for the majority of users.
        const identity = OPAQUE.makeIdentity(
          lowercaseUsername,
          password,
          badgeSalt
        );
        const alpha2 = OPAQUE.mask(identity.key);
        await this.doLogin(lowercaseUsername, identity.index, alpha2);
      } catch {
        // Failing that, try the original provided username.
        const identity = OPAQUE.makeIdentity(username, password, badgeSalt);
        const alpha2 = OPAQUE.mask(identity.key);
        await this.doLogin(username, identity.index, alpha2);
      }

      // We need to upgrade the index to match the new method so we can skip
      // these old index styles.
      const { output: beta } = await this.encryptedCall(v1.OPRF_Me)({
        alpha: alpha.point,
      });
      const unmasked = OPAQUE.unmask(beta, alpha.mask);
      const encrypted = OPAQUE.encrypt(unmasked, {
        pk: this.publicKey,
        sk: this.privateKey,
      });

      await this.encryptedCall(v1.UpdatePassword)({
        newIndex: usernameHash,
        newEnvelope: encrypted,
      });
    }

    await this.bootstrap();
  }

  public async saveUserData(): Promise<unknown> {
    return this.encryptedCall(v1.SaveUserData)({
      data: Box.tsEncrypt(this.publicKey, this.userData, tLocUserData),
    });
  }

  public async bootstrap(): Promise<void> {
    this.contact = await this.encryptedCall(v1.GetLocContactInfo)({});

    const { keys } = await this.encryptedCall(v1.GetVersionedKeysForLoc)({});
    if (keys) {
      keys.forEach((key) => {
        this.versionedKeys[key.version] = {
          publicKey: key.publicKey,
          privateKey: Box.tsDecrypt(key.encryptedKey, this.publicKey, this.privateKey, tKey)
        };
      });
    }

    this.isLocStar = await this.encryptedCall(v1.IsLocStar)({});
    this.isAbleToRewriteShares = await this.encryptedCall(v1.IsAbleToRewriteShares)({});

    const { encryptedUserData } = await this.encryptedCall(v1.BootstrapUser)({});
    if (encryptedUserData) {
      this.userData = Box.tsDecrypt(encryptedUserData, this.publicKey, this.privateKey, tLocUserData);
    } else {
      this.userData = {};
    }
  }

  // Account Setup =============================================================
  public async checkPasswordStrength(password: string): Promise<void> {
    if (password.length < 8) {
      throw new Error('too short');
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const hash = sha1(password) as string;
    const prefix = hash.substr(0, 5);
    const { hashes } = await this.call(v1.CheckPasswordStrength)({ prefix });

    if (hashes.includes(hash)) {
      throw new Error('found match');
    }
  }

  public async validateAccountToken(token: string): Promise<{
    name: string;
    email: string;
    practice: string;
    state: string;
  }> {
    return (await this.call(v1.ValidateLocAccountToken)({ token }));
  }

  public async validateActivationToken(token: string): Promise<{
    id: string;
    name: string;
    email: string;
  }> {
    return (await this.encryptedCall(v1.ValidateLocActivationToken)({ token }));
  }

  public async activateNewLoc(newLocId: string): Promise<void> {
    const { locPublicKey } =
      await this.encryptedCall(v1.GetLocPublicKey)({ locId: newLocId });

    const encryptedVersionedKeys: {
      version: number;
      key: Uint8Array;
    }[] = [];
    Object.keys(this.versionedKeys).forEach((version) => {
      const { privateKey } = this.versionedKeys[version] as { publicKey: Uint8Array; privateKey: Uint8Array };
      const encryptedPrivateKey = Box.tsEncrypt(locPublicKey, privateKey, tKey);
      encryptedVersionedKeys.push({
        version: parseInt(version, 10),
        key: encryptedPrivateKey
      });
    });
    const { success } = await this.encryptedCall(v1.SaveVersionedKeysForNewLoc)({
      locId: newLocId,
      keys: encryptedVersionedKeys
    });

    if (!success) {
      throw new Error('Error activating the new LOC. Please inform the DLOC.');
    }
  }

  // Backup Codes ==============================================================
  public async createBackups(
    n: number,
    salt = commonBadgeSalt
  ): Promise<string[]> {
    const encryptedEnvelopes: { [index: string]: Uint8Array } = {};
    const codes: string[] = [];

    for (let i = 0; i < n; i++) {
      const backup = OPAQUE.createBackup(
        this.username,
        {
          pk: this.publicKey,
          sk: this.privateKey,
        },
        salt
      );

      const index = to_hex(crypto_hash_sha256(backup.code));
      encryptedEnvelopes[index] = backup.encrypted;
      codes.push(backup.code);
    }

    await this.encryptedCall(v1.SaveBackupCodes)({ codes: encryptedEnvelopes });
    return codes;
  }

  public async emailBackupCodes(email: string, codes: string[]): Promise<void> {
    await this.encryptedCall(v1.MailBackupCodes)({
      email,
      codes,
    });
  }

  public async useBackupCode(code: string, salt = commonBadgeSalt): Promise<void> {
    const index = to_hex(crypto_hash_sha256(code));

    // TODO: error handling.
    const resp = await this.call(v1.UseBackupCode_Step1_Find)({ code: index });

    this.userID = resp.userID;
    this.serverKey = resp.serverPublicKey;

    // Stage all the information needed for finalizing the request.
    const decrypted = OPAQUE.decryptBackup(resp.encryptedEnvelope, code, salt);
    this.username = decrypted.username;
    this.publicKey = decrypted.keys.pk;
    this.privateKey = decrypted.keys.sk;
  }

  public async burnBackupCode(
    code: string,
    newPassword: string,
    badgeSalt: string = commonBadgeSalt
  ): Promise<unknown> {
    const codeIndex = to_hex(crypto_hash_sha256(code));

    const passwordHash = OPAQUE.makePassword(newPassword, badgeSalt);

    const alpha = OPAQUE.mask(passwordHash);
    const { output: beta } = await this.encryptedCall(v1.OPRF_Me)({
      alpha: alpha.point,
    });

    const unmasked = OPAQUE.unmask(beta, alpha.mask);
    const encrypted = OPAQUE.encrypt(unmasked, {
      pk: this.publicKey,
      sk: this.privateKey,
    });

    return this.encryptedCall(v1.UseBackupCode_Step3_Finalize)({
      encryptedEnvelope: encrypted,
      codeIndex,
    });
  }

  // Account Management ========================================================
  /**
   * updatePassword creates a new envelope and badge for the user and saves
   * them.
   */
  public async updatePassword(oldPassword: string, newPassword: string, badgeSalt = commonBadgeSalt): Promise<unknown> {
    // Essentially perform a login to verify that the user has entered
    // their correct password to confirm their intent.
    const usernameHash = OPAQUE.makeUsername(this.username);
    const oldPasswordHash = OPAQUE.makePassword(oldPassword, badgeSalt);
    const oldAlpha = OPAQUE.mask(oldPasswordHash);

    const loginData = await this.call(v1.Login)({
      index: usernameHash,
      alpha: oldAlpha.point,
      passwordCheck: true
    });

    const oldUnmasked = OPAQUE.unmask(loginData.beta, oldAlpha.mask);
    OPAQUE.decrypt(oldUnmasked, loginData.encryptedEnvelope);

    // If the decryption succeeded, the user entered their correct password
    // and we should carry out the password change.

    const newPasswordHash = OPAQUE.makePassword(newPassword, badgeSalt);
    const newAlpha = OPAQUE.mask(newPasswordHash);

    const { output: beta } = await this.encryptedCall(v1.OPRF_Me)({
      alpha: newAlpha.point,
    });

    const newUnmasked = OPAQUE.unmask(beta, newAlpha.mask);
    const encrypted = OPAQUE.encrypt(newUnmasked, {
      pk: this.publicKey,
      sk: this.privateKey,
    });

    return this.encryptedCall(v1.UpdatePassword)({
      newIndex: OPAQUE.makeUsername(this.username),
      newEnvelope: encrypted,
    });
  }

  public async getContactInfo(): Promise<{
    name: string;
    email: string;
    practice: string;
    state: string;
  }> {
    return await this.encryptedCall(v1.GetLocContactInfo)({});
  }

  public async updateContactInfo(
    name: string,
    email: string,
    practice: string,
    state: string
  ): Promise<unknown> {
    if (email !== this.contact.email) {
      const currentContactName = this.contact.name;
      const currentState = this.contact.state;
      const currentPractice = this.contact.practice;

      try {
        this.contact.name = name;
        this.contact.practice = practice;
        this.contact.state = state;
        await this.resetAccountRecovery(email, this.userData.phoneNumber);
      } catch (e) {
        this.contact.name = currentContactName;
        this.contact.state = currentState;
        this.contact.practice = currentPractice;
        throw e;
      }
    } else {
      return this.encryptedCall(v1.UpdateLocContactInfo)({
        name,
        email,
        practice,
        state,
      });
    }
  }

  // Account Recovery
  public async getRecoveryClient(): Promise<RecoveryClient> {
    if(this.recoveryClient !== undefined) {
      return this.recoveryClient;
    }

    const env = await this.getEnvironmentVariables();
    this.recoveryClient = new RecoveryClient(
      env.UI_ENV_RECOVERY_SERVER_1_URL,
      env.UI_ENV_RECOVERY_SERVER_2_URL,
    );

    return this.recoveryClient;
  }

  public async verifyAccountRecoveryToken(token: string): Promise<{
    success: boolean;
    questions: string[];
  }> {
    const client = await this.getRecoveryClient();
    const questions = await client.recovery_step1_get_questions(token);

    return Promise.resolve({
      success: true,
      questions
    });
  }

  public async validateSecurityQuestionAnswers(
    answers: string[],
    token: string
  ): Promise<boolean> {
    try {
      const client = await this.getRecoveryClient();
      const envelope = await client.recovery_step2_complete(token, answers);

      const { serverKey } = await this.call(v1.GetServerPublicKey)({});
      this.userID = envelope.userID;
      this.username = envelope.username;
      this.publicKey = envelope.envelope.pk;
      this.privateKey = envelope.envelope.sk;
      this.serverKey = serverKey;
      return true;
    } catch (e) {
      try {
        await this.submitEvent('Validate security question answers', { error: (e as Error).message });
      } catch (error) {
        // Swallow this error
      }
      return false;
    }
  }

  public async setUpAccountRecovery(
    email: string,
    phoneNumber: string,
    securityQuestions: string[],
    answers: string[]
  ): Promise<{ success: boolean }> {
    const { isActive } = await this.encryptedCall(v1.GetCurrentUserActiveStatus)({});
    if (!isActive) {
      throw new Error('Your account recovery data cannot be set up. Please contact a DLOC for more information.');
    }

    const questions = new Map<string, string>();
    for (let i = 0; i < securityQuestions.length; i++) {
      questions.set(securityQuestions[i], answers[i]);
    }

    if (!this.userData) {
      this.userData = {};
    }

    let marker: Uint8Array;
    if (this.userData.marker) {
      marker = this.userData.marker;
    } else {
      marker = randombytes_buf(64);
      this.userData.marker = marker;
    }

    // Create an ownership key for this entry so that we can delete it later
    // if we need to.
    const keys = Matching.makeOwnershipKey();
    this.userData.recoveryOwnershipKey = keys.privateKey;
    await this.saveUserData();

    const recoveryClient = await this.getRecoveryClient();
    await recoveryClient.setup(
      email,
      phoneNumber,
      questions,
      this.userID,
      this.username,
      marker,
      {
        pk: this.publicKey,
        sk: this.privateKey
      },
      keys.publicKey,
      'l'
    );
    await this.updateAccountRecoveryData(email, phoneNumber, securityQuestions, answers);
    return { success: true };
  }

  public async updateAccountRecoveryData(
    email: string,
    phone: string,
    securityQuestions: string[] = this.userData.securityQuestions,
    answers: string[] = this.userData.securityAnswers
  ): Promise<void> {
    try {
      if (email !== this.contact.email) {
        if (!this.serverKey) {
          const { serverKey } = await this.call(v1.GetServerPublicKey)({});
          this.serverKey = serverKey;
        }

        await this.encryptedCall(v1.UpdateLocContactInfo)({
          name: this.contact.name,
          email,
          practice: this.contact.practice,
          state: this.contact.state
        });

        this.contact.email = email;

        try {
          const event = {
            success: true
          };
          await this.submitEvent('Update LOC email', event);
        } catch {
          // Swallow this error
        }
      }

      this.userData.securityQuestions = securityQuestions;
      this.userData.securityAnswers = answers;
      this.userData.phoneNumber = phone;
      await this.saveUserData();
    } catch (error) {
      try {
        const data = {
          error: (error as Error).message,
          success: false,
        };
        await this.submitEvent('LOC update account recovery data', data);
      } catch {
        // Swallow this error
      }
      throw new Error('There was an error updating your account information; please try again');
    }
  }

  public async resetAccountRecovery(
    email: string,
    phoneNumber: string,
    securityQuestions: string[] = this.userData.securityQuestions,
    answers: string[] = this.userData.securityAnswers
  ): Promise<{ success: boolean }> {
    const { isActive } = await this.encryptedCall(v1.GetCurrentUserActiveStatus)({});
    if (!isActive) {
      throw new Error('Your account recovery data cannot be updated. Please contact a DLOC for more information.');
    }

    const questions = new Map<string, string>();
    for (let i = 0; i < securityQuestions.length; i++) {
      questions.set(securityQuestions[i], answers[i]);
    }
    const client = await this.getRecoveryClient();
    try {
      await client.update(
        this.contact.email,
        this.userData.phoneNumber,
        email,
        phoneNumber,
        questions,
        this.userID,
        this.username,
        this.userData.marker,
        {
          pk: this.publicKey,
          sk: this.privateKey
        },
        this.userData.recoveryOwnershipKey,
        'l'
      );
      await this.updateAccountRecoveryData(email, phoneNumber, securityQuestions, answers);
    } catch (e) {
      try {
        await this.submitEvent('LOC reset account recovery', {error: (e as Error).message});
      } catch {
        // swallow this error
      }
      throw new Error('There was an error updating your data. Please try again.');
    }
    return { success: true };
  }

  // Matching Entries ==========================================================
  public async getAssignedEntries(): Promise<inner.locEntry[]> {
    const { entries: rawEntries } = await this.encryptedCall(v1.GetLocEntries)(
      {}
    );

    const entries: Record<string, inner.locEntry> = {};
    const userToEntries: Record<string, string[]> = {};
    for (const i in rawEntries) {
      if (rawEntries.hasOwnProperty(i)) {
        try {
          const e = rawEntries[i];
          const versionedKeyBox: inner.versionedKeyBox =
            Box.tsDecrypt(e.encryptedKey, this.publicKey, this.privateKey, tVersionedKeyBox);
          const k: Uint8Array = Box.tsDecrypt(
            versionedKeyBox.key,
            this.versionedKeys[versionedKeyBox.version].publicKey,
            this.versionedKeys[versionedKeyBox.version].privateKey,
            tKey
          );
          let decrypted: inner.entryData;
          try {
            decrypted = Secretbox.decrypt(e.encrypted, k) as inner.entryData;
          } catch (error) {
            // Don't let errors stop the LOC from working with cases that do work.
            await this.submitEvent('Decrypt raw entry', { error: (error as Error).message, caseId: i });
            continue;
          }

          const userID = decrypted.userID;
          if (userToEntries.hasOwnProperty(userID)) {
            userToEntries[userID].push(i);
          } else {
            userToEntries[userID] = [i];
          }

          this.caseNotes[i] = e.notes.map((note) => {
            let decryptedBox: inner.encryptedBox;
            try {
              decryptedBox = Box.tsDecrypt(
                note.encrypted,
                this.publicKey,
                this.privateKey,
                tEncryptedBox
              );
            } catch (error) {
              // Don't let errors stop the LOC from working with cases that do work.
              void this.submitEvent('Decrypt case notes keybox', { error: (error as Error).message, caseId: i });
              return null;
            }
            let decryptedNote: legalNotes;
            try {
              decryptedNote = Box.tsDecrypt(
                decryptedBox.encrypted,
                this.versionedKeys[decryptedBox.keyVersion].publicKey,
                this.versionedKeys[decryptedBox.keyVersion].privateKey,
                tLegalNotes
              );
            } catch (error) {
              // Don't let errors stop the LOC from working with cases that do work.
              void this.submitEvent('Decrypt case notes', { error: (error as Error).message, caseId: i });
              return null;
            }

            decryptedNote.id = note.notesId;
            return decryptedNote;
          });

          entries[i] = {
            id: i,
            created: e.created,
            lastEdited: e.lastEdited,
            assigned: e.dateAssigned,
            matchFound: e.matchFound,
            entry: decrypted,
            userContactInfo: null,
            userSignupCampus: null,
            userCampusAtTimeOfEntryCreation: e.userCampusOnCreation,
            status: inner.CaseStatus[e.status],
            statusChanged: e.statusChanged,
            perpID: e.perpetratorID,
            numMatches: e.numMatchedUsers,
            closeCaseSurveyCompleted: e.closeCaseSurveyCompleted,
            survivorHasManyEntries: e.survivorHasManyEntries
          };
        } catch (error) {
          // Don't let errors stop the LOC from working with cases that do work.
          await this.submitEvent('Decrypt case', { error: (error as Error).message, caseId: i });
        }
      }
    }

    if (Object.keys(entries).length === 0) {
      return [];
    }

    // Batch the contact info updates so that we don't need to call the
    // endpoint multiple times.
    const { users } = await this.encryptedCall(v1.GetContactInfoForUsers)({
      ids: Object.keys(userToEntries),
    });

    Object.entries(users).map(async ([userID, data]) => {
      const versionedKeyBox: inner.versionedKeyBox = Box.tsDecrypt(
        data.encryptedKey,
        this.publicKey,
        this.privateKey,
        tVersionedKeyBox
      );
      const key: Uint8Array = Box.tsDecrypt(
        versionedKeyBox.key,
        this.versionedKeys[versionedKeyBox.version].publicKey,
        this.versionedKeys[versionedKeyBox.version].privateKey,
        tKey
      );

      try {
        const contact = Secretbox.decrypt(data.encrypted, key) as inner.contactInfo;

        userToEntries[userID].map((entryID) => {
          entries[entryID].userContactInfo = contact;
          entries[entryID].userSignupCampus = data.campus;
        });
      } catch (error) {
        await this.submitEvent('Decrypt contact info', { error: (error as Error).message, survivorId: userID });
      }
    });

    return Object.values(entries);
  }

  public async reassignCase(entryId: string, survivorId: string): Promise<unknown> {
    return await this.encryptedCall(v1.RequestCaseReassignment)({ entryId, survivorId });
  }

  public async updateCaseStatus(
    entryID: string,
    newStatus: inner.CaseStatus,
    dateOfUpdate: Date
  ): Promise<unknown> {
    return await this.encryptedCall(v1.UpdateStatus)({
      entryID,
      dateOfUpdate,
      newStatus,
    });
  }

  public async setCaseClosed(
    entryId: string,
    status: inner.CaseStatus,
    statusChangeDate: Date,
    surveyResponse: SurveyResponse[]
  ): Promise<boolean> {
    try {
      const { success } = await this.encryptedCall(v1.SetCaseClosed)({
        entryId,
        status,
        statusChangeDate,
        surveyResponse
      });

      return success;
    } catch (error) {
      const data = {
        caseId: entryId,
        status,
        success: false,
        error: (error as Error).message
      };
      try {
        await this.submitEvent('Close case', data);
      } catch {
        // Swallow it
      }
      throw new Error('There was an error saving the status update. Please try again.');
    }
  }

  public async registerAccount(
    username: string,
    password: string,
    token: string = this.token,
    badgeSalt: string = commonBadgeSalt
  ): Promise<void> {
    let encrypted: Uint8Array;
    try {
      const lcUsername = username.toLowerCase();

      const usernameHash = OPAQUE.makeUsername(lcUsername);
      const passwordHash = OPAQUE.makePassword(password, badgeSalt);
      const alpha = OPAQUE.mask(passwordHash);

      const keys = OPAQUE.generateKeys();
      const oprfData = await this.call(v1.CreateAccount_Step1_OPRF)({
        token,
        index: usernameHash,
        alpha: alpha.point,
        userPublicKey: keys.pk,
        privacyPolicyAccepted: null,
        campusName: null,
        emailDomain: null
      });

      const unmasked = OPAQUE.unmask(oprfData.beta, alpha.mask);
      encrypted = OPAQUE.encrypt(unmasked, keys);

      this.username = lcUsername;
      this.userID = oprfData.userID;
      this.serverKey = oprfData.serverPublicKey;
      this.publicKey = keys.pk;
      this.privateKey = keys.sk;
    } catch (error) {
      const data = {
        token,
        success: false,
        error: (error as Error).message
      };
      try {
        await this.submitEvent('Register LOC account', data);
      } catch {
        // Swallow it
      }
      throw error;
    }

    try {
      await this.encryptedCall(v1.CreateAccount_Step2_Finalize)({
        envelope: encrypted
      });
    } catch (err) {
      try {
        await this.submitEvent('Create account step 2', { error: (err as Error).message });
      } catch {
        // fail silently
      }

      try {
        await this.encryptedCall(v1.UndoCreateAccountStep1)({});
      } catch (e) {
        try {
          await this.submitEvent('Roll back create account step 1', { error: (e as Error).message });
        } catch {
          // fail silently
        }
        throw new Error('Error creating account. Please ask a DLOC to restart the process.');
      } finally {
        await this.logout();
      }
      throw new Error ('Error creating account. Please try submitting again.');
    }
  }

  public async generateSharedKey(): Promise<void> {
    try {
      const { locKeys } = await this.encryptedCall(v1.GetLOCKeys)({});
      const newKeyVersion = this.getLatestSharedKeyVersion() + 1;

      const sharedKeys = KeyGenerationHelper.generateSharedKeys();
      const encryptedSecretKeys = KeyGenerationHelper.encryptSharedKeys(locKeys, sharedKeys.privateKey);
      await this.encryptedCall(v1.SaveVersionedSharedKeys)({
        version: newKeyVersion,
        sharedPublicKey: sharedKeys.publicKey,
        encryptedSecretKeys: encryptedSecretKeys.map(({ userId, encryptedKey }) => ({
          locId: userId,
          encryptedKey
        }))
      });
      this.versionedKeys[newKeyVersion] = {
        publicKey: sharedKeys.publicKey,
        privateKey: sharedKeys.privateKey
      };
    } catch (error) {
      const data = {
        success: false,
        error: (error as Error).message
      };
      try {
        await this.submitEvent('Generate New Version of Shared LOC Keys', data);
      } catch {
        // Swallow it
      }

      throw new Error('Key generation failed');
    }
  }

  public async submitEvent(action: string, data: Record<string, unknown>) {
    const event = {
      action,
      ...data,
      service_name: 'legal',
      name: action
    };
    await this.call(v1.HoneycombEvent)({ event });
  }

  public async submitNotes(caseId: string, notes: string): Promise<string> {
    const keyVersion = this.getLatestSharedKeyVersion();
    const now = new Date();
    const formalNotes: legalNotes = {
      createdDate: now,
      modifiedDate: now,
      notes
    };

    try {
      const encryptedNotes = Box.tsEncrypt(this.versionedKeys[keyVersion].publicKey, formalNotes, tLegalNotes);

      const { notesId } = await this.encryptedCall(v1.SubmitCaseNotes)({
        caseId,
        keyVersion,
        encryptedNotes
      });

      if (!this.caseNotes[caseId]) {
        this.caseNotes[caseId] = [];
      }
      formalNotes.id = notesId;
      this.caseNotes[caseId].push(formalNotes);
      return notesId;
    } catch {
      throw new Error('There was an error saving your notes. Please try again.');
    }
  }

  public async updateNotes(caseId: string, notesId: string, notes: string): Promise<void> {
    const keyVersion = this.getLatestSharedKeyVersion();
    const oldNotes = this.caseNotes[caseId].find((note) => note.id === notesId);
    const formalNotes: legalNotes = {
      id: notesId,
      createdDate: oldNotes.createdDate,
      modifiedDate: new Date(),
      notes
    };

    try {
      const encryptedNotes = Box.tsEncrypt(this.versionedKeys[keyVersion].publicKey, formalNotes, tLegalNotes);

      await this.encryptedCall(v1.UpdateCaseNotes)({
        notesId,
        keyVersion,
        encryptedNotes
      });
    } catch {
      throw new Error('There was an error saving your notes. Please try again.');
    }

    this.caseNotes[caseId] = this.caseNotes[caseId].filter((note) => note.id !== notesId);
    this.caseNotes[caseId].push(formalNotes);
  }

  public async recalculateShares(): Promise<boolean> {
    let errors = false;
    const { entries } = await this.encryptedCall(v1.GetEntriesForShareRecalculation)({});
    const entryIds = Object.keys(entries);
    const { results: keysAndMarkers } = await this.encryptedCall(v1.GetEntryKeysAndUserMarkersByEntryIds)({ entryIds });

    const recomputedShares: Record<string, {
      dek: Uint8Array;
      x: string;
      y: string;
      type: string;
    }[]> = {};
    for (const entryId of entryIds) {
      let k: Uint8Array;
      try {
        const encryptedEntry = entries[entryId];
        const encryptedKey = keysAndMarkers[entryId].entryKey;
        const keyBox: inner.versionedKeyBox = Box.tsDecrypt(encryptedKey, this.publicKey, this.privateKey, tVersionedKeyBox);
        k = Box.tsDecrypt(
          keyBox.key,
          this.versionedKeys[keyBox.version].publicKey,
          this.versionedKeys[keyBox.version].privateKey,
          tKey
        );
        const decryptedEntry = Secretbox.decrypt(encryptedEntry, k) as inner.entryData;

        const { apiShares } = await computeShares(decryptedEntry, keysAndMarkers[entryId].userMarker, this);
        recomputedShares[entryId] = apiShares;
        const data = {
          success: true
        };
        try {
          await this.submitEvent(`Recompute shares for ${entryId}`, data);
        } catch {
          // Swallow the error
        }
      } catch (e) {
        const data: Record<string, unknown> = {
          success: false,
          error: (e as Error).message
        };
        if ((e as Error).message === 'wrong secret key for the given ciphertext') {
          data.key = to_hex(k);
        }
        try {
          await this.submitEvent(`Recompute shares for ${entryId}`, data);
        } catch {
          // Swallow this error
        }
        errors = true;
      }
    }

    try {
      await this.encryptedCall(v1.RewriteShares)(recomputedShares);
      const data = {
        success: true
      };
      try {
        await this.submitEvent('Save recomputed shares', data);
      } catch {
        // Swallow errors
      }
    } catch (e) {
      const data = {
        success: false,
        error: (e as Error).message
      };
      try {
        await this.submitEvent('Save recomputed shares', data);
      } catch {
        // Swallow it
      }

      errors = true;
    }

    return !errors;
  }

  private async doLogin(username: string, index: string, alpha: OPRF.Alpha) {
    const oprfData = await this.call(v1.Login)({
      index,
      alpha: alpha.point,
      passwordCheck: false
    });

    const unmasked = OPAQUE.unmask(oprfData.beta, alpha.mask);
    const decrypted = OPAQUE.decrypt(unmasked, oprfData.encryptedEnvelope);

    this.username = username;
    this.userID = oprfData.userID;
    this.serverKey = oprfData.serverPublicKey;
    this.publicKey = decrypted.pk;
    this.privateKey = decrypted.sk;
  }

  private getLatestSharedKeyVersion = () => {
    if (Object.keys(this.versionedKeys).length > 0) {
      const keyVersions = Object.keys(this.versionedKeys);
      const maxSharedKeyVersion = keyVersions
        .sort((a, b) => parseInt(b, 10) - parseInt(a, 10))[0];
      return  parseInt(maxSharedKeyVersion, 10);
    } else {
      return 0;
    }
  };
}
