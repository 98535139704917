/* eslint-disable react/react-in-jsx-scope */
import 'regenerator-runtime/runtime';

import {
  ComponentChildren,
  createContext,
  h,
  render
} from 'preact';
import {
  ready as _sodium_ready,
  to_base64
} from 'libsodium-wrappers-sumo';

import { LoginOut } from './client';

export const AuthClientContext = createContext<null | LoginOut>(null);

// Boostrap the logged-in environment if we can.
const AUTH_STORAGE_KEY = '__callisto_auth';

const waitForCreds = async (client: LoginOut) => {
  const o = window.sessionStorage.getItem(AUTH_STORAGE_KEY);

  if (o !== null) {
    // TODO: catch exceptions here and notify the user that something went
    // wrong so they can attempt to reload.
    const e = Buffer.from(o, 'base64').toString('utf-8');
    client.deserialize(e);
    await client.bootstrap();

    window.sessionStorage.removeItem(AUTH_STORAGE_KEY);
  } else {
    // Try to get from the window that opened us.
    const opener = window.opener as Window;
    if (opener !== null) {
      const port = new window.MessageChannel();

      await new Promise((resolve) => {
        port.port1.onmessage = (ev: MessageEvent<string>) => {
          client.deserialize(ev.data);
          void client.bootstrap().then(resolve);
        };
      });
      opener.postMessage('CREDS', '*', [port.port2]);
    }
  }

  window.addEventListener('beforeunload', () => {
    if (client.loggedIn) {
      const serialized = client.serialize();
      const e = to_base64(serialized);
      window.sessionStorage.setItem(AUTH_STORAGE_KEY, e);
    }
  });

  window.addEventListener('message', (ev: MessageEvent<string>) => {
    if (ev.origin !== window.origin) {
      return;
    }

    if (ev.data === 'CREDS') {
      ev.ports[0].postMessage(client.serialize());
    }
  });
};

export const renderShell = async (app: ComponentChildren, client: LoginOut): Promise<void> => {
  await _sodium_ready;

  try {
    // Wait until either some credential handler is executed, or we find nothing,
    // until we render the Preact application.
    await waitForCreds(client);
  } catch (e) {
    if ((e as Error).message === 'User deactivated') {
      await client.logout();
      window.sessionStorage.removeItem(AUTH_STORAGE_KEY);
    } else {
      throw e;
    }
  }
  render(<div id="app">{app}</div>, document.querySelector('#root'));
};
