import {
  useAction,
  useSelector
} from '@preact-hooks/unistore';
import {
  useContext,
  useEffect
} from 'preact/hooks';
import createStore, { BoundAction } from 'unistore';
import devtools from 'unistore/devtools';
import { AuthClientContext } from '.';

export interface AppState {
  loggedIn: boolean;
  wasLoggedIn: boolean;
}

const initialStore = {
  loggedIn: false,
  wasLoggedIn: false,
  showAutoLogoutModal: false,
  countdownSecs: 60,
  cancelCountdownFunc: () => {},
  confirmCountdownFunc: () => {},

  backupCodes: [],
  backupCode: '',
  stillGeneratingBackupCodes: false,
};

export const store =
  process.env.NODE_ENV === 'production'
    ? createStore(initialStore)
    : devtools(createStore(initialStore));

// Actions =====================================================================
export const login = store.action(() => ({ loggedIn: true, countdownSecs: 60 }));

export const hideLogoutToast = store.action(() => ({ wasLoggedIn: false }));

export const showLogoutModal = store.action(
  (_store, cancel: () => void, confirm: () => void) => ({
    showAutoLogoutModal: true,
    cancelCountdownFunc: cancel,
    confirmCountdownFunc: confirm,
  }));

export const hideLogoutModal = store.action(() => ({ showAutoLogoutModal: false }));

export const setCountdownSecs = store.action((_state, t: number) => ({ countdownSecs: t }));

// Hooks =====================================================================
export const useAuthState = (): {
  loggedIn: boolean;
  login: BoundAction;
  wasLoggedIn: boolean;
  logout: BoundAction;
  clearWasLoggedIn: BoundAction;
} => {
  const authClient = useContext(AuthClientContext);
  useSelector((state: AppState) => state.loggedIn); // Removing this line breaks the top navbar auto update on log in
  const wasLoggedIn = useSelector((state: AppState) => state.wasLoggedIn);
  const logoutAction = useAction(() => ({
    loggedIn: false,
    countdownSecs: 0,
  }));
  const loginAction = useAction(() => ({
    loggedIn: true,
    wasLoggedIn: true,
    countdownSecs: 60,
  }));
  const clearWasLoggedIn = useAction((state: AppState) => ({
    ...state,
    wasLoggedIn: false,
  }));

  useEffect(() => {
    if (authClient.loggedIn) {
      loginAction();
    } else {
      logoutAction();
    }
  }, []);

  return {
    loggedIn: authClient.loggedIn,
    login: loginAction,
    wasLoggedIn,
    logout: logoutAction,
    clearWasLoggedIn,
  };
};
