/* eslint-disable react/react-in-jsx-scope,@typescript-eslint/require-await */
import { useState } from 'preact/hooks';
import { Fragment, h } from 'preact';
import * as emailValidator from 'email-validator';

import { Modal } from '../../modal';

import jsPDF from 'jspdf';

import './styles.scss';

const LogoPng = require('./Callisto_logo_only.png') as string;

interface Client {
  emailBackupCodes: (email: string, codes: string[]) => Promise<void>;
}

const RenderCodes = ({ codes }: { codes: string[] }) => (
  <Fragment>
    {codes.map((code) => <p className="backupCode" key={code}>{code}</p>)}
  </Fragment>
);

const RenderLoading = () => (
  <div id="codeLoader">
    <div className='loader'/>
    <br />
    <p>This could take a couple minutes.</p>
  </div>
);

const getBase64Image = (img: CanvasImageSource) => {
  console.log('gettingBase64Image');
  const canvas = document.createElement('canvas');
  canvas.width = img.width as number;
  canvas.height = img.height as number;
  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.drawImage(img, 0, 0);
  }
  const dataURL = canvas.toDataURL('image/png');
  return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
};

const downloadPDF = (codes: string[]) => {
  const doc = new jsPDF();
  doc.setProperties({
    title: 'Callisto Vault backup codes',
    subject: 'Keep these in a safe place',
    author: 'Callisto',
    keywords: 'backup, codes, Callisto, account, Vault',
    creator: 'Callisto',
  });
  doc.setFontSize(12);
  const img = new Image();
  img.src = LogoPng ;
  doc.addImage(getBase64Image(img), 'png', 91, 12, 23, 20); // img, type, left-offset, top-offset, width, height

  doc.setDrawColor(205, 205, 205);
  doc.roundedRect(10, 80, 190, 45, 3, 3, 'S'); // left-offset, top-offset, width, height, radius, radius, style

  doc.text(
    'Keep these backup codes somewhere safe but accessible. You use these backup codes to recover' +
    '\ryour account in case you forget your password.',
    10,
    45,
    { align: 'left' }
  );
  doc.text(
    'In the event you forget your password, you will not be able to recover your account without a\rbackup code.',
    10,
    60,
    { align: 'left' }
  );

  let line = 90;
  codes.forEach((code) => {
    doc.text(code, 100, line, { align: 'center' }); // text, left-offset, top-offset, position
    line = line + 7;
  });

  doc.save('callisto-backup-codes.pdf');
};

const ButtonsRow = ({
  client,
  codes,
  onClose,
}: {
  client: Client;
  codes: string[];
  onClose: () => void;
}) => {
  const [showEmail, setShowEmail] = useState(false);
  const [email, setEmail] = useState('');
  const [errText, setErrorText] = useState('');

  const sendEmail = () => {
    if (!emailValidator.validate(email)) {
      setErrorText('Please enter a valid email');
      return;
    }

    const loader = document.getElementById('modalLoader');
    loader.style.display = 'flex';

    client
      .emailBackupCodes(email, codes)
      .then(() => {
        setShowEmail(false);
      })
      .catch((err) => {
        setErrorText(err);
      })
      .finally(() => {
        loader.style.display = 'none';
      });
  };

  if (!showEmail) {
    return (
      <div id="ctaWrappers">
        <div id="codesButtons" className="hideOnPrint">
          <button
            id="completeButton"
            className="secondaryButton"
            onClick={() => setShowEmail(true)}
          >
            Email
          </button>
          <button className="primaryButton" onClick={() => downloadPDF(codes)}>
            Download
          </button>
        </div>
        <div id="confirmation">
          <p onClick={onClose}>I stored these backup codes somewhere safe.</p>
        </div>
        {codes.length === 0 ? <div id='disableCTAs'/> : null}
      </div>
    );
  }

  return (
    <Fragment>
      <label htmlFor="backupCodesEmail">
        <b>Enter your email</b>
      </label>
      <br />
      <input
        type="text"
        id="backupCodesEmail"
        value={email}
        onChange={(evt) => setEmail(evt.currentTarget.value)}
      />
      <p className="errorText">{errText}</p>
      <div id="codesButtons" className="hideOnPrint">
        <button className="secondaryButton" onClick={() => setShowEmail(false)}>
          Back
        </button>
        <button className="primaryButton" onClick={sendEmail}>
          Send email
        </button>
      </div>
    </Fragment>
  );
};

export const BackupCodesModal = ({
  client,
  onClose,
  codes,
}: {
  client: Client;
  onClose: () => void;
  codes: string[];
}): JSX.Element => (
  <Modal closeFunc={async () => onClose()} canExit={false}>
    <div id="modalBackup">
      <img src={LogoPng} hidden={true} alt='Callisto logo' />
      <h3>Save your backup codes</h3>
      <p className="backupCopy">
          Keep these backup codes somewhere safe but accessible. You use these
          backup codes to recover your account in case you forget your password.
      </p>
      <p className="backupCopy">
          In the event you forget your password, you will not be able to recover
          your account without a backup code.
      </p>
      {codes.length !== 0 ? (
        <div id="backupCodes">
          <RenderCodes codes={codes} />
        </div>
      ) : (
        <RenderLoading />
      )}

      <ButtonsRow client={client} onClose={onClose} codes={codes} />
    </div>
  </Modal>
);
