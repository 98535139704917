import { FieldData } from '../../../../../../lib/questionnaire/formFunctions';

export const noContactSurveyQuestions: FieldData[] = [
  {
    key: 'numberOfAttempts',
    label: 'How many attempts did you make to get in touch with the survivor?',
    type: 'number',
    // required: true,
    layout: {
      insertBreak: true
    }
  },
  {
    key: 'numberOfHours',
    label: 'How many hours did you work on this case?',
    type: 'number',
    // required: true,
    layout: {
      insertBreak: true
    }
  }
];

export const closeCaseSurveyQuestions: FieldData[] = [
  {
    key: 'howLearnedAboutCallisto',
    label: 'How did the Survivor find out about Callisto?',
    type: 'radio',
    value: 'howLearnedAboutCallisto',
    // required: true,
    options: [
      {
        value: 'CampusEvent',
        label: 'Campus event'
      },
      {
        value: 'FriendOrStudent',
        label: 'Friend/Student'
      },
      {
        value: 'TherapistOrCounselor',
        label: 'Therapist/Counselor'
      },
      {
        value: 'SocialMedia',
        label: 'Social media'
      },
      {
        value: 'Search',
        label: 'Google Search'
      },
      {
        value: 'Ambassador',
        label: 'Callisto Ambassador'
      },
      {
        value: 'Ddn',
        label: 'Desi Dance Network Partnership'
      },
      {
        value: 'UniversityOfficial',
        label: 'University Official'
      },
      {
        value: 'Other',
        question: {
          key: 'other',
          label: 'Other:',
          type: 'text',
          value: 'other'
        }
      },
      {
        value: 'NotDiscussed',
        label: 'Did not discuss'
      }
    ]
  },
  {
    key: 'survivorKnowAboutLOCs',
    label: 'Did the Survivor know they would be connected with a pro bono attorney?',
    type: 'radio',
    // required: true,
    options: [
      {
        value: 'Yes',
        label: 'Yes'
      },
      {
        value: 'No',
        label: 'No'
      },
      {
        value: 'Unsure',
        label: 'Unsure'
      }
    ]
  },
  {
    key: 'firstContact',
    label: 'Was this the first time the survivor was contacted by an LOC?',
    type: 'radio',
    // required: true,
    options: [
      {
        value: 'Yes',
        label: 'Yes'
      },
      {
        value: 'No',
        label: 'No'
      },
      {
        value: 'Unsure',
        label: 'Unsure'
      }
    ]
  },
  {
    key: 'howLongToMatch',
    label: 'How long did it take the survivor to match after entering into the Matching System?',
    type: 'radio',
    // required: true,
    options: [
      {
        value: '1-30days',
        label: '1-30 day(s)'
      },
      {
        value: '1-3months',
        label: '1-3 month(s)'
      },
      {
        value: '4-6months',
        label: '4-6 months'
      },
      {
        value: '6months-1yr',
        label: '6 months - 1 year'
      },
      {
        value: '1-2years',
        label: '1-2 years'
      },
      {
        value: 'Over2years',
        label: 'over 2 years'
      },
      {
        value: 'Unsure',
        label: 'Unsure'
      }
    ]
  },
  {
    key: 'howLongToGetInTouch',
    label: 'How long did it take you to get in touch with the survivor?',
    type: 'radio',
    // required: true,
    options: [
      {
        value: 'LessThan24Hours',
        label: '24 hours or less'
      },
      {
        value: '1-6days',
        label: '1-6 days'
      },
      {
        value: '1-2weeks',
        label: '1-2 weeks'
      },
      {
        value: '2-4weeks',
        label: '2-4 weeks'
      },
      {
        value: '1-3months',
        label: '1-3 months'
      },
      {
        value: 'MoreThan3Months',
        label: 'More than 3 months'
      }
    ]
  },
  {
    key: 'numberOfAttempts',
    label: 'How many attempts did you make to get in touch with the survivor?',
    type: 'number',
    // required: true,
    layout: {
      insertBreak: true
    }
  },
  {
    key: 'numberOfHours',
    label: 'How many hours did you work on this case?',
    type: 'number',
    // required: true,
    layout: {
      insertBreak: true
    }
  },
  {
    key: 'survivorBiggestConcerns',
    label: "What were the survivor's biggest concerns? (check all that apply)",
    type: 'multiselect',
    // required: true,
    options: [
      {
        value: 'BeingBelieved',
        label: 'Being believed'
      },
      {
        value: 'PerpetratorBacklash',
        label: 'Perpetrator backlash'
      },
      {
        value: 'InsufficientEvidence',
        label: 'Insufficient evidence to pursue a criminal or civil case'
      },
      {
        value: 'Privacy',
        label: 'Privacy concerns'
      },
      {
        value: 'Immigration',
        label: 'Immigration needs or concerns'
      },
      {
        value: 'Other',
        question: {
          key: 'other',
          value: 'other',
          label: 'Other:',
          type: 'text'
        }
      }
    ]
  },
  {
    key: 'issuesAdvisedOn',
    label: 'What issues did you advise the survivor on? (check all that apply)',
    type: 'multiselect',
    // required: true,
    options: [
      {
        value: 'ReportingToTitleIX',
        label: 'Reporting to Title IX/Requesting Accommodations/Getting Access to My Education'
      },
      {
        value: 'SafetyPlanning',
        label: 'Safety Planning or Getting a Protective Order (ex: restraining order, no contact order, etc.)'
      },
      {
        value: 'ReportingToPoliceOrUnderstandingCriminalJusticeSystem',
        label: 'Reporting to Police or Understanding the Criminal Justice System'
      },
      {
        value: 'MentalHealth',
        label: 'Mental Health'
      },
      {
        value: 'MedicalServices',
        label: 'Medical Services (including abortion)'
      },
      {
        value: 'ConcernsAboutPrivacy',
        label: 'Concerns about privacy'
      },
      {
        value: 'Employment',
        label: 'Questions about Employment'
      },
      {
        value: 'ImmigrationNeeds',
        label: 'Immigration Needs or Concerns'
      },
      {
        value: 'StatuteOfLimitations',
        label: 'Statute of Limitations'
      },
      {
        value: 'HousingOrSocialServices',
        label: 'Access to Housing or Social Services'
      },
      {
        value: 'SocialMedia',
        label: 'Posting on Social Media'
      },
      {
        value: 'RestorativeJustice',
        label: 'Engaging in Restorative Justice'
      },
      {
        value: 'Other',
        question: {
          key: 'other',
          label: 'Other:',
          type: 'textarea'
        }
      }
    ]
  },
  {
    key: 'actionsTaken',
    label: 'What action(s), if any, did the survivor decide to take? (check all that apply)',
    type: 'multiselect',
    // required: true,
    options: [
      {
        value: 'PursueCriminalCase',
        label: 'Pursue a criminal case'
      },
      {
        value: 'PursueCivilCase',
        label: 'Pursue a civil case'
      },
      {
        value: 'ReportToTitleIX',
        label: 'Report to Title IX'
      },
      {
        value: 'TakeTimeOff',
        label: 'Take time off of school/work'
      },
      {
        value: 'ProtectiveOrder',
        label: 'Get a protective order (ex: restraining order, no contact order, ect.)'
      },
      {
        value: 'ReportToPolice',
        label: 'Report to law enforcement'
      },
      {
        value: 'ReportToOtherAuthority',
        label: 'Report to another authority such as HR'
      },
      {
        value: 'AddressImmigration',
        label: 'Address immigration needs or concerns'
      },
      {
        value: 'ConfontOffenders',
        label: 'Confront the offender(s)'
      },
      {
        value: 'PostOnSocialMedia',
        label: 'Post on social media'
      },
      {
        value: 'CoordinateWithOtherSurvivors',
        label: 'Coordinate with other survivor(s) of the same offender(s)'
      },
      {
        value: 'TellFriendFamilyOrLovedOne',
        label: 'Tell a friend, family member, and/or loved one'
      },
      {
        value: 'EngageInRestorativeJustice',
        label: 'Engage in the restorative justice process'
      },
      {
        value: 'SeekTherapy',
        label: 'Seek therapy/mental health support'
      },
      {
        value: 'SeekMedicalServices',
        label: 'Seek medical services'
      },
      {
        value: 'StillDeciding',
        label: 'Still deciding'
      },
      {
        value: 'Nothing',
        label: 'Nothing'
      },
      {
        value: 'Other',
        question: {
          key: 'other',
          label: 'Other:',
          type: 'text'
        }
      },
      {
        value: 'Unsure',
        label: 'Unsure',
        exclusive: true
      }
    ]
  },
  {
    key: 'wantToCoordinate',
    label: 'Did the survivor want to coordinate with other survivor(s)?',
    type: 'radio',
    // required: true,
    options: [
      {
        value: 'YesConnected',
        label: 'Yes. They were connected'
      },
      {
        value: 'YesButAdvisedNotTo',
        label: 'Yes, but it was advised that they should not connect'
      },
      {
        value: 'YesButOtherDidNotWantToConnect',
        label: 'Yes, but the other survivor(s) did not want to connect'
      },
      {
        value: 'No',
        label: 'No. The survivor did not want to connect'
      },
      {
        value: 'Other',
        question: {
          key: 'other',
          label: 'Other:',
          type: 'text'
        }
      }
    ]
  },
  {
    key: 'whyCoordinateAction',
    label: 'If the survivor wanted to coordinate action, what was the reason? (check all that apply)',
    type: 'multiselect',
    options: [
      {
        value: 'PursueHealing',
        label: 'To pursue healing'
      },
      {
        value: 'NotAlone',
        label: "Felt that they weren't alone"
      },
      {
        value: 'DiscussCriminalCivilCase',
        label: 'Wanted to discuss a criminal/civil case'
      },
      {
        value: 'DiscussTitleIXCase',
        label: 'Wanted to discuss a Title IX case'
      },
      {
        value: 'Other',
        question: {
          key: 'other',
          label: 'Other:',
          type: 'text'
        }
      }
    ]
  },
  {
    key: 'groundsToPursueCivilCase',
    label: 'Do you believe the survivor has grounds to pursue a civil case?',
    type: 'radio',
    options: [
      {
        value: 'Yes',
        label: 'Yes'
      },
      {
        value: 'Possibly',
        label: 'Possibly'
      },
      {
        value: 'No',
        label: 'No'
      },
      {
        value: 'Unsure',
        label: 'Unsure'
      }
    ]
  },
  {
    key: 'groundsToPursueCriminalCase',
    label: 'Do you believe the survivor has grounds to pursue a criminal case?',
    type: 'radio',
    options: [
      {
        value: 'Yes',
        label: 'Yes'
      },
      {
        value: 'Possibly',
        label: 'Possibly'
      },
      {
        value: 'No',
        label: 'No'
      },
      {
        value: 'Unsure',
        label: 'Unsure'
      }
    ]
  },
  {
    key: 'groundsToPursueTitleIXCase',
    label: 'Do you believe the survivor has grounds to pursue a Title IX case?',
    type: 'radio',
    options: [
      {
        value: 'Yes',
        label: 'Yes'
      },
      {
        value: 'Possibly',
        label: 'Possibly'
      },
      {
        value: 'No',
        label: 'No'
      },
      {
        value: 'Unsure',
        label: 'Unsure'
      }
    ]
  },
  {
    key: 'moreThanOneSurvivorHelpsCriminalOrCivilCase',
    label: 'If the survivor has either a civil or criminal case to pursue,' +
      ' do you think that knowing there are more than one survivor helps their case?',
    type: 'radio',
    options: [
      {
        value: 'Yes',
        label: 'Yes'
      },
      {
        value: 'Possibly',
        label: 'Possibly'
      },
      {
        value: 'No',
        label: 'No'
      },
      {
        value: 'Unsure',
        label: 'Unsure'
      },
      {
        value: 'Other',
        question: {
          key: 'other',
          label: 'Other:',
          type: 'text'
        }
      }
    ]
  },
  {
    key: 'moreThanOneSurvivorHelpsTitleIXCase',
    label: 'If the survivor has a Title IX case to pursue,' +
      ' do you think that knowing there are more than one survivor helps their case?',
    type: 'radio',
    options: [
      {
        value: 'Yes',
        label: 'Yes'
      },
      {
        value: 'Possibly',
        label: 'Possibly'
      },
      {
        value: 'No',
        label: 'No'
      },
      {
        value: 'Unsure',
        label: 'Unsure'
      },
      {
        value: 'Other',
        question: {
          key: 'other',
          label: 'Other:',
          type: 'text'
        }
      }
    ]
  },
  {
    key: 'survivorAppearToGetRelief',
    label: 'Did the survivor appear to get relief from talking with you?',
    type: 'radio',
    options: [
      {
        value: 'Yes',
        label: 'Yes'
      },
      {
        value: 'No',
        label: 'No'
      },
      {
        value: 'Unsure',
        label: 'Unsure'
      }
    ]
  },
  {
    key: 'reportToAuthorities',
    label: 'Did the survivor report to authorities? (check all that apply)',
    type: 'multiselect',
    options: [
      {
        value: 'TitleIX',
        question: {
          key: 'reportToTitleIX',
          label: 'Title IX',
          type: 'radio',
          options: [
            {
              value: 'BeforeMatching',
              label: 'Before matching',
              className: 'level2radio'
            },
            {
              value: 'AfterMatching',
              label: 'After Matching',
              className: 'level2radio'
            },
            {
              value: 'Unsure',
              label: 'Unsure',
              className: 'level2radio'
            }
          ]
        }
      },
      {
        value: 'LawEnforcement',
        question: {
          key: 'reportToLawEnforcement',
          label: 'Law Enforcement',
          type: 'radio',
          options: [
            {
              value: 'BeforeMatching',
              label: 'Before matching',
              className: 'level2radio'
            },
            {
              value: 'AfterMatching',
              label: 'After Matching',
              className: 'level2radio'
            },
            {
              value: 'Unsure',
              label: 'Unsure',
              className: 'level2radio'
            }
          ]
        }
      },
      {
        value: 'HR',
        question: {
          key: 'reportToHR',
          label: 'HR',
          type: 'radio',
          options: [
            {
              value: 'BeforeMatching',
              label: 'Before matching',
              className: 'level2radio'
            },
            {
              value: 'AfterMatching',
              label: 'After Matching',
              className: 'level2radio'
            },
            {
              value: 'Unsure',
              label: 'Unsure',
              className: 'level2radio'
            }
          ]
        }
      },
      {
        value: 'Other',
        question: {
          key: 'other',
          label: 'Other:',
          type: 'textWithOptions',
          options: [
            {
              value: 'BeforeMatching',
              label: 'Before matching',
              type: 'textRadioOption',
              className: 'level2radio'
            },
            {
              value: 'AfterMatching',
              label: 'After Matching',
              type: 'textRadioOption',
              className: 'level2radio'
            },
            {
              value: 'Unsure',
              label: 'Unsure',
              type: 'textRadioOption',
              className: 'level2radio'
            }
          ]
        }
      },
      {
        value: 'Unsure',
        label: 'Unsure',
        exclusive: true
      }
    ]
  },
  {
    key: 'referToAnotherAttorney',
    label: 'Did you refer the survivor to another attorney? If so, who?',
    type: 'text',
    layout: {
      insertBreak: true
    }
  },
  {
    key: 'anythingElseWeShouldKnow',
    label: 'Is there anything else we should know?',
    type: 'textarea',
    layout: {
      insertBreak: true
    }
  }
];
