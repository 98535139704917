/* eslint-disable react/react-in-jsx-scope */
import {
  Fragment,
  h
} from 'preact';
import { JSXInternal } from 'preact/src/jsx';
import { FieldData } from '../../../../../../lib/questionnaire/formFunctions';
import { resultType } from '../../../../../../lib/data';

interface ElementProps {
  path: string[];
  qKey: string;
  label: string;
  onChange: (e: JSXInternal.TargetedEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  value: string | string[];
  required?: boolean;
  options?: {
    value: string;
    label?: string;
    question?: FieldData;
    type?: 'textRadioOption' | 'textMultiselectOption';
    className?: string;
    exclusive?: boolean;
  }[];

  getElement?: (
    data: FieldData,
    path: string[],
    results: Map<string, resultType>,
    setResults: (newResults: Map<string, resultType>) => void,
    disabled?: boolean
  ) => JSX.Element;

  results?: Map<string, resultType>;

  setResults?: (newResults: Map<string, resultType>) => void;

  insertBreak?: boolean;

  className?: string;

  disabled?: boolean;
  resultContainsKey?: boolean;
  name?: string;
}

interface SubOptionProps extends ElementProps {
  qKey: string;
}

interface OptionProps {
  okey: string;
  value: string | string[];
  label: string;
  name?: string;
  checked: boolean;
  onChange: (e: JSXInternal.TargetedEvent<HTMLInputElement>) => void;
  className?: string;
  disabled?: boolean;
  required?: boolean;
}

export const TextInput = (props: ElementProps): JSX.Element => {
  const {
    qKey,
    label,
    onChange,
    insertBreak,
    className,
    disabled,
    options,
    getElement,
    results,
    setResults,
    path,
    value,
    name,
    required
  } = props;

  const exclusiveKeys: string[] = [];
  if (options) {
    options.forEach((opt) => {
      if (opt.exclusive) {
        exclusiveKeys.push(qKey + opt.value);
      }
    });
  }

  return (
    <span>
      <label htmlFor={qKey}>{label}</label>{insertBreak && <br />}{!insertBreak && ' '}
      <input id={qKey} type='text' className={className} onChange={onChange}
        disabled={disabled} value={value} required={required} /><br />
      {(options ?? []).map((opt) => {
        if (getElement && opt.label) {
          return getElement({
            key: qKey + opt.value,
            label: opt.label,
            value: opt.value,
            type: opt.type,
            exclusiveKeys,
            name,
            required,
            layout: {
              className: opt.className
            }
          }, [...path, qKey + opt.value], results, setResults, disabled);
        }
        return <div key={qKey + opt.value}></div>;
      })}
    </span>
  );
};

export const TextAreaInput = (props: ElementProps): JSX.Element => {

  const {
    qKey,
    label,
    onChange,
    insertBreak,
    className,
    disabled,
    value,
    required
  } = props;

  return (
    <span>
      <label htmlFor={qKey}>{label}</label>{insertBreak && <br />}{!insertBreak && ' '}
      <textarea id={qKey} className={className} onChange={onChange} disabled={disabled}
        value={value} required={required} />
    </span>
  );
};

export const NumberInput = (props: ElementProps): JSX.Element => {


  const {
    qKey,
    label,
    onChange,
    insertBreak,
    className,
    disabled,
    required
  } = props;

  return (
    <span>
      <label htmlFor={qKey}>{label}</label>{insertBreak && <br />}
      <input id={qKey} type='number' className={className} onChange={onChange}
        disabled={disabled} required={required} />
    </span>
  );
};

export const RadioInput = (props: ElementProps): JSX.Element => {
  const {
    qKey,
    label,
    options,
    getElement,
    results,
    setResults,
    disabled,
    path,
    name,
    required
  } = props;

  return (
    <span>
      <Fragment>
        <label htmlFor={qKey}>{label}</label><br />
        {(options ?? []).map((opt) => {
          if (getElement && opt.label) {
            return getElement({
              key: qKey + opt.value,
              label: opt.label,
              value: opt.value,
              type: 'radioOption',
              name,
              required,
              layout: {
                className: opt.className
              }
            }, [...path, qKey + opt.value], results, setResults, disabled);
          } else if (getElement && opt.question) {
            return <div key={qKey + opt.value + 'div'}>
              {getElement({
                key: qKey + opt.value,
                value: '',
                label: opt.question.label,
                type: 'subradio',
                name,
                required
              }, [...path, qKey + opt.value], results, setResults)}
              {getElement({ ...opt.question, key: qKey + opt.value, required }, [...path, qKey + opt.value], results, setResults)}
            </div>;
          }

          return <div key={qKey + opt.value}></div>;
        })}
      </Fragment>
    </span>
  );
};

export const MultiselectInput = (props: ElementProps): JSX.Element => {
  const {
    qKey,
    label,
    options,
    getElement,
    results,
    setResults,
    path,
    disabled
  } = props;

  const exclusiveKeys: string[] = [];
  if (options) {
    options.forEach((opt) => {
      if (opt.exclusive) {
        exclusiveKeys.push(qKey + opt.value);
      }
    });
  }

  return (
    <span>
      <label htmlFor={qKey}>{label}</label><br />
      {(options ?? []).map((opt) => {
        if (getElement && opt.label) {
          return getElement({
            key: qKey + opt.value,
            label: opt.label,
            value: opt.value,
            type: 'multiselectOption',
            exclusiveKeys,
            layout: {
              className: opt.className
            }
          }, [...path, qKey + opt.value], results, setResults, disabled);
        } else if (getElement && opt.question) {
          return <div key={qKey + opt.value + 'div'}>
            {getElement({
              key: qKey + opt.value,
              label: opt.question.label,
              value: '',
              type: 'submultiselect',
              exclusiveKeys
            }, [...path, qKey + opt.value], results, setResults, disabled)}
            {getElement({ ...opt.question, key: qKey + opt.value, exclusiveKeys }, [...path, qKey + opt.value], results, setResults)}
          </div>;
        }

        return <div key={qKey + opt.value}></div>;
      })}
    </span>
  );
};

export const SubRadioInput = (props: SubOptionProps): JSX.Element => {
  const {
    qKey,
    value,
    disabled,
    resultContainsKey,
    onChange,
    className,
    name,
    required
  } = props;
  return (
    <input type='radio' id={qKey} key={value as string} value={value}
      checked={!disabled && resultContainsKey} onChange={onChange} required={required}
      className={`radioButton ${className}`} disabled={disabled} name={name} />
  );
};

export const SubMultiselectInput = (props: SubOptionProps): JSX.Element => {
  const {
    qKey,
    value,
    disabled,
    resultContainsKey,
    onChange,
    className
  } = props;
  return (
    <input type='checkbox' id={qKey} key={value as string} value={value}
      checked={!disabled && resultContainsKey}
      onChange={onChange} className={`checkbox ${className}`}
      disabled={disabled}
    />
  );
};

export const RadioOption = (props: OptionProps): JSX.Element => {
  const {
    okey,
    value,
    label,
    checked,
    onChange,
    className,
    disabled,
    name,
    required
  } = props;
  return (
    <div key={okey + 'div'}>
      <input type='radio' id={okey} key={okey} value={value} checked={!disabled && checked}
        onChange={onChange} className={`radioButton ${className}`} disabled={disabled}
        name={name} required={required}
      />
      <label htmlFor={okey}>{label}</label>
    </div>
  );
};

export const MultiselectOption = (props: OptionProps): JSX.Element => {
  const {
    value,
    disabled,
    onChange,
    className,
    label,
    checked,
    okey
  } = props;
  return (
    <div key={okey + 'div'}>
      <input type='checkbox' id={okey} key={okey} value={value}
        checked={!disabled && checked}
        onChange={onChange} className={`checkbox ${className}`}
        disabled={disabled}
      />
      <label htmlFor={okey}>{label}</label>
    </div>
  );
};
