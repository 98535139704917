/* eslint-disable react/react-in-jsx-scope */
import {
  Fragment,
  h
} from 'preact';
import { route } from 'preact-router';
import { useState } from 'preact/hooks';
import { Toast } from '../toast';
import PhoneNumberInput from '../PhoneNumberInput';

import './styles.scss';
import { SecurityQuestionTips } from '../SecurityQuestionTips';
import { TargetedEvent } from 'preact/compat';

interface AccountClient {
  contactEmail?: string;
  contact?: {
    email: string;
  };
  setUpAccountRecovery: (
    email: string,
    phoneNumber: string,
    securityQuestions: string[],
    securityAnswers: string[]
  ) => Promise<{ success: boolean }>;
  submitEvent: (action: string, data: Record<string, string>) => Promise<void>;
}

interface AccountRecoverySetupForm {
  email: string;
  phoneNumber: string;
  confirmPhoneNumber: string;
  question1: string;
  question2: string;
  question3: string;
  answer1: string;
  answer2: string;
  answer3: string;
}

export const question1Options = [
  'What was the full name of the first school you attended?',
  'To what city did you go the first time you flew on a plane?',
  'What was the destination of your most memorable childhood vacation?'
];

export const question2Options = [
  "What is your oldest sibling's middle name?",
  'What was the surname of your teacher in your first year of school?',
  'What was the name of your first stuffed animal?'
];

export const question3Options = [
  'What was the surname of your first boss?',
  "What was your childhood best friend's nickname?",
  "What was the name of your childhood best friend's pet?"
];

const questionSelectStyle = {
  color: 'Gray',
  backgroundColor: '#F5F7F7',
  borderColor: 'lightgray',
  borderRadius: '0',
  fontSize: 'large',
  width: '100%',
  padding: '5px',
  marginBottom: '13px'
};

const answerInputStyle = {
  backgroundColor: '#F5F7F7',
  border: '1px solid',
  borderColor: 'lightgray',
  fontSize: 'large',
  width: '100%',
  height: '2em'
};

export const RecoverySetup = ({
  client
}: {
  client: AccountClient;
  path?: string;
}): h.JSX.Element => {
  const [showError, setShowError] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [formValues, setFormValues] = useState<AccountRecoverySetupForm>({
    email: client.contactEmail ?? client.contact?.email,
    phoneNumber: '',
    confirmPhoneNumber: '',
    question1: '',
    question2: '',
    question3: '',
    answer1: '',
    answer2: '',
    answer3: ''
  });

  const onSubmit = async (input: AccountRecoverySetupForm) => {
    const loader = document.getElementById('loaderWrapper');
    loader.style.display = 'flex';
    try {
      if (validateInput(input)) {
        const { success } = await client.setUpAccountRecovery(
          input.email,
          input.phoneNumber,
          [input.question1, input.question2, input.question3],
          [input.answer1, input.answer2, input.answer3]
        );

        if (success) {
          setShowSuccess(true);
          route('/dashboard');
        } else {
          setShowError(true);
        }
      }
    } catch (error) {
      await client.submitEvent('Account recovery setup', { error: (error as Error).message });
      setShowError(true);
    } finally {
      loader.style.display = 'none';
    }
  };

  const validateInput = (input: AccountRecoverySetupForm) => {
    let errorsFound = false;
    const errorMessages: Record<string, string> = {};
    if (!input.email) {
      errorsFound = true;
      errorMessages.email = 'ERROR: No email address found. Please try logging in again, then filling out this form.';
    }

    if (!input.phoneNumber) {
      errorsFound = true;
      errorMessages.phoneNumber = 'Please enter a phone number';
    }

    if (!input.confirmPhoneNumber) {
      errorsFound = true;
      errorMessages.confirmPhoneNumber = 'Please re-enter phone number';
    } else if (input.confirmPhoneNumber !== input.phoneNumber) {
      errorsFound = true;
      errorMessages.confirmPhoneNumber = 'Phone numbers do not match';
    }

    if (!input.question1) {
      errorsFound = true;
      errorMessages.question1 = 'Please select a question';
    }

    if (!input.answer1) {
      errorsFound = true;
      errorMessages.answer1 = 'Please answer the question';
    }

    if (!input.question2) {
      errorsFound = true;
      errorMessages.question2 = 'Please select a question';
    }

    if (!input.answer2) {
      errorsFound = true;
      errorMessages.answer2 = 'Please answer the question';
    }

    if (!input.question3) {
      errorsFound = true;
      errorMessages.question3 = 'Please select a question';
    }

    if (!input.answer3) {
      errorsFound = true;
      errorMessages.answer3 = 'Please answer the question';
    }

    setErrors(errorMessages);
    return !errorsFound;
  };

  return (
    <Fragment>
      <main id="recoverySetupPage">
        <section className="formWrapper">
          <div id="titleWrapper">
            <h1>Set Up Account Recovery</h1>
          </div>
          <div id="credForm">
            <p>
              If you are a returning user and you just logged in, you have been redirected here because
              we have enabled a new form of account recovery since your last login. To continue, you must
              set up the account recovery process.
            </p>

            <p>
              If you have just created credentials for a new account then account recovery setup is required
              before you can complete account setup.
            </p>

            <p>The easiest and most secure way to recover your account is using a backup code.
              This set up is so you can recover your account even if you lose your backup codes.
              This should not be considered your primary method of recovering your account.
              Try not to lose your backup codes.
            </p>

            <p>
              Note: The phone number will be formatted as a US number on the screen. International numbers will be saved
              properly even if they are not displayed as desired as you input them.
            </p>

            <p><b>Email:</b> {formValues.email}</p>
            {errors.email && (
              <p style={{ color: 'red' }}>{errors.email}</p>
            )}

            <label>Phone number*</label>
            <PhoneNumberInput
              value={formValues.phoneNumber}
              onChange={(phone) => {
                setFormValues({
                  ...formValues,
                  phoneNumber: phone
                });
              }}
            />
            {errors.phoneNumber && (
              <p style={{ color: 'red' }}>{errors.phoneNumber}</p>
            )}

            <div style={{ marginTop: '15px' }}><label>Confirm phone number*</label>
              <PhoneNumberInput
                value={formValues.confirmPhoneNumber}
                onChange={(phone) => {
                  setFormValues({
                    ...formValues,
                    confirmPhoneNumber: phone
                  });
                }}
              /></div>
            {errors.confirmPhoneNumber && (
              <p style={{ color: 'red' }}>{errors.confirmPhoneNumber}</p>
            )}
          </div>

          <h2 style={{ textAlign: 'center', fontSize: '36px'}}>Security Questions</h2>
          <SecurityQuestionTips />

          <div style={{ paddingTop: '30px', paddingBottom: '30px' }}>
            <select
              placeholder="Select a question"
              style={questionSelectStyle}
              onChange={(e: TargetedEvent<HTMLSelectElement>) => {
                e.preventDefault();
                setFormValues({
                  ...formValues,
                  question1: e.currentTarget.value
                });
              }}
            >
              <option value="" selected={true} disabled={true}>Select a Question</option>
              {question1Options.map((opt) => (
                <option value={opt} key={opt}>{opt}</option>
              ))}
            </select>
            {errors.question1 && (
              <p style={{ color: 'red' }}>{errors.question1}</p>
            )}
            <input
              placeholder="Answer*"
              style={answerInputStyle}
              onChange={(e: TargetedEvent<HTMLInputElement>) => {
                e.preventDefault();
                setFormValues({
                  ...formValues,
                  answer1: e.currentTarget.value
                });
              }}
            />
            {errors.answer1 && (
              <p style={{ color: 'red' }}>{errors.answer1}</p>
            )}
            <div style={{margin: '60px'}}></div>

            <select
              placeholder="Select a question"
              style={questionSelectStyle}
              onChange={(e: TargetedEvent<HTMLSelectElement>) => {
                e.preventDefault();
                setFormValues({
                  ...formValues,
                  question2: e.currentTarget.value
                });
              }}
            >
              <option value="" selected={true} disabled={true}>Select a Question</option>
              {question2Options.map((opt) => (
                <option value={opt} key={opt}>{opt}</option>
              ))}
            </select>
            {errors.question2 && (
              <p style={{ color: 'red' }}>{errors.question2}</p>
            )}

            <input
              placeholder="Answer*"
              style={answerInputStyle}
              onChange={(e: TargetedEvent<HTMLInputElement>) => {
                e.preventDefault();
                setFormValues({
                  ...formValues,
                  answer2: e.currentTarget.value
                });
              }}
            />
            {errors.answer2 && (
              <p style={{ color: 'red' }}>{errors.answer2}</p>
            )}
            <div style={{margin: '60px'}}></div>

            <select
              placeholder="Select a question"
              style={questionSelectStyle}
              onChange={(e: TargetedEvent<HTMLSelectElement>) => {
                e.preventDefault();
                setFormValues({
                  ...formValues,
                  question3: e.currentTarget.value
                });
              }}
            >
              <option value="" selected={true} disabled={true}>Select a Question</option>
              {question3Options.map((opt) => (
                <option value={opt} key={opt}>{opt}</option>
              ))}
            </select>
            {errors.question3 && (
              <p style={{ color: 'red' }}>{errors.question3}</p>
            )}

            <input
              placeholder="Answer*"
              style={answerInputStyle}
              onChange={(e: TargetedEvent<HTMLInputElement>) => {
                e.preventDefault();
                setFormValues({
                  ...formValues,
                  answer3: e.currentTarget.value
                });
              }}
            />
            {errors.answer3 && (
              <p style={{ color: 'red' }}>{errors.answer3}</p>
            )}
          </div>
          <div style={{margin: '30px'}}></div>

          <div style={{textAlign: 'center'}}>
            <button
              className="primaryButton"
              style={{marginBottom: '5px'}}
              onClick={async () => {
                await onSubmit(formValues);
              }}>
              Submit
            </button>
          </div>
        </section>

        <div id="loaderWrapper">
          <div className="loaderContainer">
            <div className="loader" />
          </div>
        </div>
      </main>
      { showError && (
        <Toast
          error={true}
          message="There was an error setting up account recovery. Please try again."
          closeFunc={async () => {
            setShowError(false);
            return Promise.resolve();
          }}
        />
      )}

      {showSuccess && (
        <Toast
          error={false}
          message={'Account recovery setup successful'}
          closeFunc={async () => {
            setShowSuccess(false);
            return Promise.resolve();
          }}
        />
      )}
    </Fragment>
  );
};
