/* eslint-disable react/react-in-jsx-scope */
import {
  Component,
  h
} from 'preact';
import { createPortal } from 'preact/compat';

import './styles.scss';

interface Props {
  error: boolean;
  message: string;
  closeFunc: () => Promise<void>;
  timeout?: number;
}

export class Toast extends Component<Props, Record<string, unknown>> {
  componentDidMount = (): void => {
    // Set a timer to destroy the toast automatically.
    const t = document.getElementById('toastAlert');
    if (t && t.style) {
      t.style.transform = 'translateY(120px)';
    }

    if (this.props.timeout === undefined || this.props.timeout === null || this.props.timeout > 0) {
      setTimeout(() => {
        void this.close();
      }, this.props.timeout ? this.props.timeout : 4000);
    }
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  close = async (): Promise<void> => {
    const t = document.getElementById('toastAlert');
    if (t && t.style) {
      t.style.transform = 'translateY(-100px)';
    }

    setTimeout(() => {
      void this.props.closeFunc();
    }, 300);
  };

  render = (): any => {
    let shield = null;
    let style = 'background-color: #BE230B';

    if (!this.props.error) {
      shield = <img src={require('./shield.svg') as string} alt="shield icon" />;
      style = 'background-color: #00375b';
    }

    return createPortal(
      <div id="toastAlert" role="alert">
        <div id="toast" style={style}>
          <p>
            {shield}
            {this.props.message}
          </p>
          <img
            src={require('./close-white.svg') as string}
            alt="close icon"
            onClick={this.close}
          />
        </div>
      </div>,
      document.querySelector('#toastRoot')
    );
  };
}
