/* eslint-disable react/react-in-jsx-scope */
import {
  Component,
  h
} from 'preact';
import { CaseStatus as DataCaseStatus, formatDate } from '../../data';

import './styles.scss';

interface Props {
  status: DataCaseStatus;
  lastUpdated?: Date;
}

export class CaseStatus extends Component<Props, Record<string, unknown>> {
  private static formatStatus(input: DataCaseStatus): string {
    // Assign a default status.
    if (input === null || input === undefined) {
      input = DataCaseStatus.NO_CONTACT_ATTEMPTED;
    }

    const arr = input.split('_');
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
    }
    return arr.join(' ');
  }

  public render(): JSX.Element {
    const cls = `${this.props.status} status`;
    return (
      <div className="caseStatus">
        <div className={cls}>
          <div className='dot'/>
          {CaseStatus.formatStatus(this.props.status)}
        </div>
        {this.props.lastUpdated && (<span className="statusChanged">
          <strong>Date Updated:</strong>{' '}
          {formatDate(this.props.lastUpdated)}
        </span>)}
      </div>
    );
  }
}
