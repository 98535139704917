/* eslint-disable react/react-in-jsx-scope */
import {
  ComponentChildren,
  Fragment,
  h
} from 'preact';
import { route } from 'preact-router';

import {
  useContext,
  useEffect,
  useRef,
  useState
} from 'preact/hooks';
import { useAuthState } from '../init/store';
import { SignoutModal } from '../init/logout-modal';
import { AuthClientContext } from '../init';

const COUNTDOWN = 60; // countdown length in seconds
const TIMEOUT = 29; // idle time before countdown starts in minutes

export const WithIdleTimer = ({ children }: { children: ComponentChildren }): JSX.Element => {
  const authClient = useContext(AuthClientContext);
  const { loggedIn } = useAuthState();
  const [showSignout, setShowSignout] = useState(false);

  const [ lastActiveTime, setLastActiveTime ] = useState<Date>(new Date());
  const [ countdownStartTime, setCountdownStartTime ] = useState<Date>(new Date(lastActiveTime.getTime() + 1000 * 60 * TIMEOUT));
  const [ countdownEndTime, setCountdownEndTime ] = useState<Date>(new Date(countdownStartTime.getTime() + 1000 * COUNTDOWN));

  const [ countdown, setCountdown ] = useState<number>(-1);
  const [ idleTime, setIdleTime ] = useState<number>(0);
  const idleTimer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress'
    ];

    const resetTimer = () => {
      if (!showSignout) {
        handleCancel();
      }
    };

    for (const evnt of events) {
      window.addEventListener(evnt, resetTimer);
    }

    window.addEventListener('keypress', handleCancel);

    return () => {
      for (const evnt of events) {
        window.removeEventListener(evnt, resetTimer);
      }

      window.removeEventListener('keypress', handleCancel);
    };
  });

  useEffect(() => {
    if (loggedIn) {
      const now = Date.now();
      if (now >= countdownEndTime.getTime()) {
        void handleLogout();
      } else if (now >= countdownStartTime.getTime()) {
        if (countdown < 0) {
          startCountdown();
        } else {
          setCountdownTime(now);
        }
      }
      idleTimer.current = setTimeout(
        () => setIdleTime((prev) => prev + 1),
        1
      );
      return () => clearTimer(idleTimer.current);
    } else {
      clearTimer(idleTimer.current);
    }
  }, [idleTime, loggedIn]);

  useEffect(() => {
    setCountdownStartTime(() => new Date(lastActiveTime.getTime() + 1000 * 60 * TIMEOUT));
  }, [lastActiveTime]);

  useEffect(() => {
    setCountdownEndTime(() =>
      countdownStartTime ? new Date(countdownStartTime.getTime() + 1000 * COUNTDOWN)
        : new Date(lastActiveTime.getTime() + 1000 * 60 * TIMEOUT + 1000 * COUNTDOWN));
  }, [countdownStartTime]);

  useEffect(() => {
    const now = Date.now();
    setCountdownTime(now);
  }, [countdownEndTime]);

  const setCountdownTime = (now: number) => {
    if (countdownStartTime.getTime() > now) {
      setCountdown(-1);
    } else {
      setCountdown(Math.ceil((countdownEndTime.getTime() - now) / 1000));
    }
  };

  const clearTimer = (timer: NodeJS.Timeout | null) => {
    if (timer !== null) {
      clearInterval(timer);
    }
  };

  const handleLogout = async () => {
    window.localStorage.removeItem('sortState');
    window.localStorage.removeItem('filters');
    await authClient.logout();
    clearTimer(idleTimer.current);
    route('/');
    setShowSignout(false);
  };

  const handleCancel = () => {
    setShowSignout(false);
    setLastActiveTime(new Date());
    setIdleTime(0);
  };

  const startCountdown = () => {
    setCountdownTime(Date.now());
    setShowSignout(true);
  };

  return (
    <Fragment>
      <div>
        {children}
      </div>
      <SignoutModal
        visible={showSignout}
        onLogout={handleLogout}
        onCancel={handleCancel}
        timeoutSec={countdown}
      />
    </Fragment>
  );
};
