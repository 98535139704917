/* eslint-disable react/react-in-jsx-scope */
import {
  Fragment,
  h
} from 'preact';
import { route } from 'preact-router';
import { useContext } from 'preact/hooks';
import { AuthClientContext } from '../../init';
import { useAuthState } from '../../init/store';

import './styles.scss';

const callistoLogo = require('./Callisto_logo.png') as string;

export interface NavItem {
  title: string;
  url: string;
  newTab: boolean;
}

export const NavLoggedOut = (): JSX.Element => (
  <Fragment>
    <li>
      <a href="/">Log In</a>
    </li>
  </Fragment>
);

export const NavLoggedIn = ({ navItems }: { navItems: NavItem[] }): JSX.Element => {
  const authClient = useContext(AuthClientContext);
  const { logout } = useAuthState();

  const handleLogout = async () => {
    window.localStorage.removeItem('sortState');
    window.localStorage.removeItem('filters');

    await authClient.logout();

    logout();

    route('/');
  };

  return (
    <Fragment>
      {navItems.map((item) => (
        <li key={item.url}>
          <a href={item.url} target={item.newTab ? '_blank' : '_self'} rel='noreferrer'>{item.title}</a>
        </li>
      ))}
      <li>
        <a href="/dashboard">Dashboard</a>
      </li>
      <li>
        <a href="/account">Account</a>
      </li>
      <li>
        <a onClick={handleLogout}>Log out</a>
      </li>
    </Fragment>
  );
};

export const Navbar = ( { navItems = [] }: { navItems?: NavItem[] }): JSX.Element => {
  const { loggedIn } = useAuthState();

  return (
    <nav id="navBar">
      <a id="brand" href="/">
        <img src={callistoLogo} alt="Callisto Logo" width="90" />
      </a>
      <ul id="navItems">{loggedIn ? <NavLoggedIn navItems={navItems} /> : <NavLoggedOut />}</ul>
    </nav>
  );
};
