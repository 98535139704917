/* eslint-disable react/react-in-jsx-scope,@typescript-eslint/require-await */
import { h } from 'preact';
import { useEffect } from 'preact/hooks';

import { Modal } from '../components/modal';

export const SignoutModal = ({
  visible,
  onCancel,
  onLogout,
  timeoutSec = 60,
}: {
  visible: boolean;
  onCancel: () => void;
  onLogout: () => void;
  timeoutSec?: number;
}): JSX.Element => {
  if (!visible) {
    return null;
  }

  useEffect(() => {
    if (timeoutSec <= 0) {
      onLogout();
    }
  }, [timeoutSec]);

  return (
    <Modal closeFunc={async () => onCancel()} canExit={true}>
      <div id="timeoutModalForm">
        <div>
          <h3>Your session is about to expire.</h3>
          <p id="backupCopy">
            You will be logged out in {timeoutSec} seconds. Do you
            want to stay logged in?
          </p>
        </div>
        <div id="codesButtons" className="hideOnPrint topPadding">
          <button
            id="completeButton"
            className="secondaryButton"
            onClick={onLogout}
          >
            No, Log Out
          </button>
          <button className="primaryButton" onClick={onCancel}>
            Yes, Stay Logged In
          </button>
        </div>
      </div>
    </Modal>
  );
};
