/* eslint-disable react/react-in-jsx-scope */
import {
  Fragment,
  h
} from 'preact';
import { useState } from 'preact/hooks';
import {
  adminUserData,
  dlocUserData,
  locUserData
} from '../data';
import PhoneNumberInput, { doFormat } from './PhoneNumberInput';
import {
  question1Options,
  question2Options,
  question3Options
} from './recovery-setup';
import { SecurityQuestionTips } from './SecurityQuestionTips';
import { Loader } from './Loader';

interface AccountRecoveryClient {
  contactEmail?: string;
  contact?: {
    email: string;
  };
  userData: dlocUserData | locUserData | adminUserData;
  resetAccountRecovery: (
    email: string,
    phoneNumber: string,
    securityQuestions: string[],
    answers: string[]
  ) => Promise<{ success: boolean }>;
  submitEvent: (action: string, data: Record<string, unknown>) => Promise<void>;
}

export const AccountRecoveryData = ({ client }: { client: AccountRecoveryClient }): h.JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [errorString, setErrorString] = useState<string>('');
  const [editState, setEditState] = useState<{
    phone: string;
    securityQuestions: string[];
    answers: string[];
  }>({
    phone: '',
    securityQuestions: [],
    answers: []
  });

  const saveAccountRecoveryData = async () => {
    try {
      const phone = editState.phone;
      if (!phone.replace(/[^0-9]/g, '')) {
        setErrorString('Please enter a phone number');
        return;
      }

      if (editState.securityQuestions.length < 3 || editState.securityQuestions.some((question) => question === '')) {
        setErrorString('Please select 3 security questions');
        return;
      }

      if (editState.answers.length < 3 || editState.answers.some((answer) => answer === '')) {
        setErrorString('Please answer all 3 security questions');
        return;
      }

      const email = client.contactEmail ?? client.contact?.email;
      if (!email) {
        setErrorString('Email address not found');
        return;
      }

      setLoading(true);

      try {
        if (changesMade()) {
          await client.resetAccountRecovery(email, phone, editState.securityQuestions, editState.answers);
        }
        closeEdit();
        setErrorString('');
      } finally {
        setLoading(false);
      }
    } catch (e) {
      try {
        await client.submitEvent('Reset account recovery data', { error: (e as Error).message });
      } catch {
        // Swallow this error
      }
      setErrorString('There was an error updating your account recovery data. Please try again.');
    }
  };

  const changesMade = () => {
    if (client.userData.phoneNumber !== editState.phone) {
      return true;
    }

    if (client.userData.securityQuestions.length !== editState.securityQuestions.length) {
      return true;
    }

    for (let i = 0; i < client.userData.securityQuestions.length; i++) {
      if (client.userData.securityQuestions[i] !== editState.securityQuestions[i]) {
        return true;
      }
    }

    if (client.userData.securityAnswers.length !== editState.answers.length) {
      return true;
    }

    for (let i = 0; i < client.userData.securityAnswers.length; i++) {
      if (client.userData.securityAnswers[i] !== editState.answers[i]) {
        return true;
      }
    }

    return false;
  };

  const openEdit = () => {
    setEditState({
      phone: client.userData.phoneNumber,
      securityQuestions: client.userData.securityQuestions,
      answers: client.userData.securityAnswers
    });
    setEditing(true);
  };

  const closeEdit = () => {
    setEditState({
      phone: client.userData.phoneNumber,
      securityQuestions: client.userData.securityQuestions,
      answers: client.userData.securityAnswers
    });
    setEditing(false);
  };

  return (
    <Fragment>
      <div id='ardLeft'>
        <h2>Account Recovery Data</h2>
        {errorString && (
          <p style={{ color: 'red' }}>{errorString}</p>
        )}
        <label>
          Phone number:
        </label>
        {editing && (
          <PhoneNumberInput
            value={editState.phone}
            onChange={(value: string) => {
              setEditState({
                ...editState,
                phone: value
              });
            }} />
        )}
        {!editing && (
          <input disabled={true} type='text' id='phoneNumber' name='phoneNumber'
            value={doFormat(editState.phone ? editState.phone : client.userData.phoneNumber)} />
        )}

        <h3 style={{ marginTop: '1em', fontSize: 'x-large' }}>Security Questions and Answers</h3>

        <div id="qAndA">
          {editing && (
            <SecurityQuestionTips baseFontSize='medium' titleFontSize='large' />
          )}

          {editing && (
            <select
              placeholder='Select a question'
              onChange={(e) => {
                e.preventDefault();
                setEditState({
                  ...editState,
                  securityQuestions: [
                    e.currentTarget.value,
                    editState.securityQuestions[1],
                    editState.securityQuestions[2]
                  ]
                });
              }}
            >
              <option value='' disabled={true}>Select a Question</option>
              {question1Options.map((opt) => (
                <option value={opt} key={opt} selected={editState.securityQuestions[0] === opt}>{opt}</option>
              ))}
            </select>
          )}
          {!editing && (
            <p>
              {client.userData.securityQuestions?.length &&
                client.userData.securityQuestions.length > 0 &&
                client.userData.securityQuestions[0] ||
                'No question selected'
              }
            </p>
          )}
          <input
            disabled={!editing}
            type={editing ? 'text' : 'password'}
            name="securityAnswer1"
            className="qAnswer"
            value={String(editState.answers.length > 0 ? editState.answers[0] : client.userData.securityAnswers[0])}
            onChange={(e) => {
              e.preventDefault();
              setEditState({
                ...editState,
                answers: [
                  e.currentTarget.value,
                  editState.answers[1],
                  editState.answers[2]
                ]
              });
            }}
          />

          {editing && (
            <select
              placeholder='Select a question'
              onChange={(e) => {
                e.preventDefault();
                setEditState({
                  ...editState,
                  securityQuestions: [
                    editState.securityQuestions[0],
                    e.currentTarget.value,
                    editState.securityQuestions[2]
                  ]
                });
              }}
            >
              <option value='' disabled={true}>Select a Question</option>
              {question2Options.map((opt) => (
                <option value={opt} key={opt} selected={editState.securityQuestions[1] === opt}>{opt}</option>
              ))}
            </select>
          )}
          {!editing && (
            <p>
              {client.userData.securityQuestions?.length &&
                client.userData.securityQuestions.length > 1 &&
                client.userData.securityQuestions[1] ||
                'No question selected'
              }
            </p>
          )}
          <input
            disabled={!editing}
            type={editing ? 'text' : 'password'}
            name="securityAnswer2"
            className="qAnswer"
            value={String(editState.answers.length > 1 ? editState.answers[1] : client.userData.securityAnswers[1])}
            onChange={(e) => {
              e.preventDefault();
              setEditState({
                ...editState,
                answers: [
                  editState.answers[0],
                  e.currentTarget.value,
                  editState.answers[2]
                ]
              });
            }}
          />

          {editing && (
            <select
              placeholder='Select a question'
              onChange={(e) => {
                e.preventDefault();
                setEditState({
                  ...editState,
                  securityQuestions: [
                    editState.securityQuestions[0],
                    editState.securityQuestions[1],
                    e.currentTarget.value
                  ]
                });
              }}
            >
              <option value='' disabled={true}>Select a Question</option>
              {question3Options.map((opt) => (
                <option value={opt} key={opt} selected={editState.securityQuestions[2] === opt}>{opt}</option>
              ))}
            </select>
          )}
          {!editing && (
            <p>
              {client.userData.securityQuestions?.length &&
                client.userData.securityQuestions.length > 2 &&
                client.userData.securityQuestions[2] ||
                'No question selected'
              }
            </p>
          )}
          <input
            disabled={!editing}
            type={editing ? 'text' : 'password'}
            name="securityAnswer3"
            className="qAnswer"
            value={String(editState.answers.length > 2 ? editState.answers[2] : client.userData.securityAnswers[2])}
            onChange={(e) => {
              e.preventDefault();
              setEditState({
                ...editState,
                answers: [
                  editState.answers[0],
                  editState.answers[1],
                  e.currentTarget.value
                ]
              });
            }}
          />
        </div>
      </div>

      <div id="ardButtons">
        <div id='ardRight'>
          {editing && (
            <Fragment>
              <button
                type="button"
                className="blankButton"
                onClick={closeEdit}
              >
                Cancel
              </button>

              <button
                type="button"
                className="primaryButton"
                onClick={saveAccountRecoveryData}
              >
                Save Changes
              </button>
            </Fragment>
          )}
          {!editing && (
            <Fragment>
              <button
                type='button'
                className='secondaryButton desktopOnly'
                onClick={() => {
                  openEdit();
                }}
              >
                Update Account Recovery Data
              </button>
              <img
                className="mobileOnly"
                src={require('../assets/edit.svg') as string}
                alt="edit pencil"
              />
            </Fragment>
          )}
        </div>
        <Loader show={loading} />
      </div>
    </Fragment>
  );
};
