/* eslint-disable react/react-in-jsx-scope */
import {
  Component,
  h
} from 'preact';
import { createPortal } from 'preact/compat';

import './styles.scss';

interface Props {
  closeFunc: () => Promise<void>;
  canExit: boolean;
  minWidth?: string;
}

export const animateOut = async (
  closeFunc: () => Promise<void>
): Promise<void> => {
  // Animate the modal as it goes away.
  const modalElement = document.getElementById('modal');
  const modalForm = document.getElementById('modalForm');

  modalForm.style.transform = 'translateY(50px)';
  modalForm.style.opacity = '0';
  setTimeout(() => {
    modalElement.style.opacity = '0';
    setTimeout(() => {
      modalElement.style.display = 'none';
      void closeFunc();
    }, 200);
  }, 100);
  return Promise.resolve();
};

export class Modal extends Component<Props, Record<string, unknown>> {
  componentDidMount = (): void => {
    // Animate the modal as it comes in.
    const modalElement = document.getElementById('modal');
    const modalForm = document.getElementById('modalForm');

    modalElement.style.display = 'flex';
    if (this.props.minWidth) {
      modalForm.style.minWidth = this.props.minWidth;
    }
    setTimeout(() => {
      modalElement.style.opacity = '1';
      modalForm.style.opacity = '1';
      modalForm.style.transform = 'translateY(0px)';
    }, 100);
  };

  // ===========================================================================
  // Closing
  closeModal = async (): Promise<void> => {
    await animateOut(this.props.closeFunc);
  };

  closeOnBgClick = async (event: Event): Promise<void> => {
    if (!this.props.canExit) {
      return;
    }

    const modalElement = document.getElementById('modal');
    if (event.target === modalElement) {
      await this.closeModal();
    }
  };

  renderCloser = (): JSX.Element => {
    if (!this.props.canExit) {
      return null;
    }

    return (
      <div id="closer" className="hideOnPrint" onClick={this.closeModal}>
        <img src={require('./close-black.svg') as string} alt="close X" />
      </div>
    );
  };

  // ===========================================================================
  render = (): any => createPortal(
    <div id="modal" onClick={this.closeOnBgClick}>
      <div id="modalForm">
        {this.props.children}
        {this.renderCloser()}
        <div id="modalLoader">
          <div className='loader'/>
        </div>
      </div>
    </div>,
    document.querySelector('#modalRoot')
  );
}
