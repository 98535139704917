/* eslint-disable react/react-in-jsx-scope,@typescript-eslint/require-await */
import {
  Fragment,
  h
} from 'preact';
import {
  animateOut,
  Modal
} from '../../../../../../lib/components/modal';

import {
  CaseStatus,
  locEntry
} from '../../../../../../lib/data';
import { route } from 'preact-router';

import './styles.scss';
import {
  useContext,
  useState
} from 'preact/hooks';
import { LegalClientContext } from '../LegalClientContext';
import { useCases } from '../../legalData';
import { useCloseCase } from '../hooks/useCloseCase';

const formatStatus = (input: CaseStatus | undefined | null) => {
  // Assign a default status.
  if (input === null || input === undefined) {
    input = CaseStatus.NO_CONTACT_ATTEMPTED;
  }

  const arr = input.split('_');
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
  }
  return arr.join(' ');
};

const formatDate = (input: Date) => {
  let month = (input.getMonth() + 1).toString();
  if (month.length < 2) {
    month = `0${month}`;
  }

  let day = input.getDate().toString();
  if (day.length < 2) {
    day = `0${day}`;
  }

  return `${input.getFullYear()}-${month}-${day}`;
};

type SimpleCB = () => void;

const RenderComplete = ({ onClose }: { onClose: SimpleCB }) => (
  <Fragment>
    <div>
      <p>
        <b>Status has been updated.</b>
      </p>
    </div>
    <div id="codesButtons" className="hideOnPrint topPadding">
      <button className="primaryButton" onClick={onClose}>
          Return to dashboard
      </button>
    </div>
  </Fragment>
);

const RenderForm = ({
  status: statusIn,
  errText,
  onConfirm,
  onClose,
}: {
  errText: string;
  status: CaseStatus;
  onClose: SimpleCB;
  onConfirm: (
    status: CaseStatus,
    changeDate: Date
  ) => void;
}) => {
  const today = new Date();
  const [status, setStatus] = useState<CaseStatus>(statusIn);
  let changeDate = today;
  const updateDate = (date) => {
    changeDate = new Date(date);
    // Necessary because the date comes in as local time and we want UTC
    changeDate.setMinutes(
      changeDate.getMinutes() + changeDate.getTimezoneOffset()
    );
  };

  return (
    <Fragment>
      <div>
        <h3>Update the status of the case</h3>
        <div id="statusInputWrapper">
          <select
            value={status}
            onChange={(e) => setStatus(e.currentTarget.value as CaseStatus)}
          >
            {Object.keys(CaseStatus).map((item: CaseStatus) => {
              if (
                item === CaseStatus.NEEDS_REASSIGNMENT ||
                item === CaseStatus.SHARE_REPAIRED ||
                item === CaseStatus.NEW_CASE ||
                item === CaseStatus.FALSE_MATCH ||
                item === CaseStatus.DELETED_ENTRY
              ) {
                return null;
              }
              return <option value={item} key={item}>{formatStatus(item)}</option>;
            })}
          </select>
          <p>
            <b>Enter date of change</b>
          </p>
          <input
            type="date"
            value={formatDate(changeDate)}
            max={formatDate(today)}
            onChange={(e) => {
              updateDate(e.currentTarget.value);
            }}
          />
        </div>
      </div>
      <p style="color: red;">{errText}</p>
      <div id="codesButtons" className="hideOnPrint topPadding">
        <button id="completeButton" className="secondaryButton" onClick={onClose}>
          Cancel
        </button>
        <button
          className="primaryButton"
          onClick={() => onConfirm(status, changeDate)}
        >
          Save changes
        </button>
      </div>
    </Fragment>
  );
};

export const EditModal = ({
  onClose,
  entry,
}: {
  onClose: SimpleCB;
  entry: locEntry;
}): JSX.Element => {
  const client = useContext(LegalClientContext);
  const [errText, setErrText] = useState('');
  const [complete, setComplete] = useState(false);
  const { refreshCases } = useCases();
  const { setCaseData } = useCloseCase();

  const returnToDash = () => {
    void animateOut(async () => {
      onClose();
      route('/dashboard');
    });
  };
  const closeOut = () => {
    void animateOut(async () => onClose());
  };

  const handleConfirm = (
    status: CaseStatus,
    changeDateIn: Date
  ) => {
    setErrText('');

    const changeDate = changeDateIn;

    if (Number.isNaN(changeDate.getTime())) {
      setErrText('Invalid date');
      return;
    }

    const modalLoader = document.getElementById('modalLoader');
    modalLoader.style.display = 'flex';

    if (status.includes('CLOSED')) {
      try {
        setCaseData(entry.id, status, changeDate);
        setComplete(true);
        route('/close-case');
      } catch (err) {
        console.log(err);
        setErrText('There was a problem updating the case. Please try again.');
      }
    } else {
      client.updateCaseStatus(entry.id, status, changeDate)
        .then(() => {
          setComplete(true);
          refreshCases();
        })
        .catch((err) => {
          console.log(err);
          setErrText('There was a problem updating the case. Please try again.');
        })
        .finally(() => {
          modalLoader.style.display = 'none';
        });
    }
  };

  return (
    <Modal closeFunc={async () => onClose()} canExit={true}>
      {complete ? (
        <RenderComplete onClose={returnToDash} />
      ) : (
        <RenderForm
          status={entry.status}
          errText={errText}
          onClose={closeOut}
          onConfirm={handleConfirm}
        />
      )}
    </Modal>
  );
};
