/* eslint-disable react/react-in-jsx-scope */
import { ComponentChildren, h } from 'preact';
import { Router, route } from 'preact-router';
import { useAuthState } from '../init/store';

export const AuthRouter = ({
  children
}: {
  children: ComponentChildren;
  path: string;
}): JSX.Element => {
  const { loggedIn } = useAuthState();

  if (!loggedIn) {
    route('/');
    return null;
  }

  return <Router>{children}</Router>;
};
